import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Link, Navigate } from "react-router-dom";
import Axios from "axios";

// Pages
import Home from "./home";
import Group from "./group";
import Groups from "./groups";
import Login from "./login";
import Register from "./register";
import Account from "./account";
import Moderate from "./moderate";
import NotFound from "./notfound";
import About from "./about";
import Terms from "./terms";
import Privacy from "./privacy";
import Admin from "./admin";

// Scripts
import GetTheme, { ChangeTheme, GetCookie, SetCookie } from "../scripts/helpers";

// Font Awesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { fab } from '@fortawesome/free-brands-svg-icons'
// prettier-ignore
import {
  faCoffee, faPlus, faExclamationCircle, faCircle, faSave, faEdit,
  faCheck, faTimes, faCog, faFilter, faGripLinesVertical, faHome, faTrash,
  faSpinner, faCloud, faCloudUploadAlt, faCloudDownloadAlt, faPlusCircle,
  faGripVertical, faEllipsisH, faChevronRight, faChevronUp, faChevronDown,
  faWrench, faUser, faSignInAlt, faFlag, faQuoteLeft, faQuoteRight, faInfoCircle,
  faUserSecret, faTools, faSearch, faUpload, faArrowUpFromBracket, faDiceFour,
  faQuestion, faPlay, faMoon, faStar, faSun, faFaceMeh, faA, faM, faMobile, faAt,
  faBars, faCaretDown, faHeart, faComments, faCircleCheck, faPizzaSlice, faUsersRectangle,
  faComment, faGears, faRefresh, faUserPlus, faRightToBracket,
} from "@fortawesome/free-solid-svg-icons"
// prettier-ignore
library.add(faCoffee, faPlus, faExclamationCircle, faCircle, faSave, faEdit,
  faCheck, faTimes, faCog, faFilter, faGripLinesVertical, faHome, faTrash,
  faSpinner, faCloud, faCloudUploadAlt, faCloudDownloadAlt, faPlusCircle,
  faGripVertical, faEllipsisH, faChevronRight, faChevronUp, faChevronDown,
  faWrench, faUser, faSignInAlt, faFlag, faQuoteLeft, faQuoteRight, faInfoCircle,
  faUserSecret, faTools, faSearch, faUpload, faArrowUpFromBracket, faDiceFour,
  faQuestion, faPlay, faMoon, faStar, faSun, faFaceMeh, faA, faM, faMobile, faAt,
  faBars, faCaretDown, faHeart, faComments, faCircleCheck, faPizzaSlice, faUsersRectangle,
  faComment, faGears, faRefresh, faUserPlus, faRightToBracket)

export default function App() {
  const [user, setUser] = useState(undefined);
  const [theme, setTheme] = useState(GetTheme());
  const [imageFile, setImageFile] = useState(process.env.REACT_APP_PROFILE_IMAGE_PATH + "/default.png");

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setImageFile(process.env.REACT_APP_PROFILE_IMAGE_PATH + user?.imageurl);
  }, [user]);

  useEffect(() => {
    ChangeTheme(theme);
  }, [theme]);

  function getUser() {
    let cookieUser = GetCookie("user");
    if (cookieUser) {
      cookieUser = JSON.parse(cookieUser)
      setUser(cookieUser);
    }

    Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/user",
    }).then((res) => {
      if (res.data) {
        res.data.fullimageurl = process.env.REACT_APP_PROFILE_IMAGE_PATH + res.data.imageurl;
        setUser(res.data);
        SetCookie("user", JSON.stringify(res.data))
      } else {
        setUser(null);
        SetCookie("user", null)
      }
    });
  }

  const updateUser = (userData) => {
    getUser();
  };

  const RequireAuth = ({ children }) => {
    const userIsLogged = user != null ?? false;
    if (!userIsLogged) {
      return <Login key="Login" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <main>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand px-1 me-1" href="/" style={{ border: "1px solid white", borderRadius: "5px" }}>
              <FontAwesomeIcon icon="pizza-slice" className="fa-flip-horizontal ms-1 aqua" /> PizzaBets<span className="title-beta">beta</span>
            </a>
            <button className="navbar-toggler" type="button" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav white text-uppercase">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <FontAwesomeIcon icon="home" />
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/Groups">
                    <FontAwesomeIcon icon="users-rectangle" />
                  </Link>
                </li> */}
                {user && (user.admin || user.moderator) ? (
                  <li className="nav-item">
                    <Link className="nav-link" to="/Moderate">
                      <FontAwesomeIcon icon="tools" />
                    </Link>
                  </li>
                ) : null
                }
                {
                  user && user.admin ? (
                    <li className="nav-item">
                      <Link className="nav-link" to="/Admin">
                        <FontAwesomeIcon icon="gears" />
                      </Link>
                    </li>
                  ) : null
                }
                {
                  user ? (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link py-0" to="/Account"><img src={imageFile} title={"Account"} className="rounded-circle" alt={user.firstname} width={40} height={40} /></Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link" to="/About">
                          <FontAwesomeIcon icon="info-circle" />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link small" to="/Login">LOGIN</Link>
                      </li>
                      <li className="nav-item mt-1">
                        <input type="checkbox" id="theme-toggle" checked={theme === "dark"} onChange={() => setTheme(theme === "dark" ? "light" : "dark")} />
                        <label htmlFor="theme-toggle" className="theme-toggle-sm"></label>
                      </li>
                    </>
                  )
                }
              </ul >
            </div >
          </div >
        </nav >
        {/* <div className="title-beta-container w-100 p-0 mx-auto my-0 text-center">
          <div className="title-beta">beta</div>
        </div> */}

        {process.env.REACT_APP_VERCEL_ENV !== "PRODUCTION" ? <div className="col-width"></div> : null}
      </main >
      <Routes>


        <Route path="*" exact={true} element={<NotFound user={user} />} />
        <Route exact path="/" element={<Home user={user} />} />
        <Route path="/g/:groupId" element={<Group user={user} />} />
        <Route path="/group/:groupId" element={<Group user={user} />} />
        <Route path="/group/:groupId/:episodeId" element={<Group user={user} />} />
        <Route path="/groups" element={<Groups user={user} />} />
        <Route path="/login" element={!user ? <Login key="Login" /> : <Navigate to={"/"} />} />
        <Route path="/register" element={!user ? <Register /> : <Navigate to={"/"} />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/account"
          element={
            <RequireAuth admin={true}>
              <Account user={user} theme={theme} setTheme={setTheme} updateUser={updateUser} />
            </RequireAuth>
          }
        />
        <Route
          path="/moderate"
          element={
            <RequireAuth>
              <Moderate user={user} />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <RequireAuth requireAdmin={"bghg"} >
              <Admin user={user} />
            </RequireAuth>
          }
        />
        <Route path="/about" element={<About />} />
      </Routes >
    </BrowserRouter >
  );
}
