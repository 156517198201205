import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

// componenets
import Bet from "../bets/bet";
import NewBetForm from "../bets/newBetForm";
import EditEpisode from "./editEpisodeForm";
import LoadingBet from "../loadingEvent";

require("dotenv").config();

export default function Episode(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [showNewBetForm, setShowNewBetForm] = useState(false);
  const [showEditBetEventForm, setShowEditBetEventForm] = useState(false);
  const [bets, setBets] = useState(null);

  useEffect(() => {
    LoadTab();
  }, []);

  async function LoadTab() {
    await getBets();
  }

  async function ShowNewBetForm() {
    setShowNewBetForm(!showNewBetForm);
  }
  async function ShowEditEventForm() {
    setShowEditBetEventForm(!showEditBetEventForm);
  }

  const AddNewBet = async (betId) => {
    try {
      console.log("Add New Bet: " + JSON.stringify(betId));
      let newBetInfo = await getBetSingle(betId);
      setBets((bets) => [...bets, newBetInfo]);
    } catch (error) {
      console.log("Issue getting single bet: ", error);
    }
  };

  const getBets = async () => {
    let eventId = props.episode.id;
    Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/" + eventId + "?searchText=" + props.searchText,
    })
      .then((res) => {
        let data = res.data;
        // console.log("All bets", JSON.stringify(data))
        setBets(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("ERROR getting bets: URL :http://localhost:4000/bets/" + eventId);
        setIsLoading(false);
      });
  };

  const UpdateBet = async (data) => {
    let getUpdatedBet = await getBetSingle(data);
    await setBets((rows) => rows.map((r) => (r.id === getUpdatedBet.id ? getUpdatedBet : r)));
  };

  const getBetSingle = async (betId) => {
    try {
      let info = await Axios({
        method: "GET",
        withCredentials: true,
        url: process.env.REACT_APP_API_URL + "/bets/getsingle/" + betId,
      }).then((res) => {
        return res.data;
      });
      return info;
    } catch (error) {
      console.log("Catch: getBetSingle", error);
    }
  };

  return (
    <div className="col-12">
      <div className="section">
        <div className="section-content">
          <div className="section-title">
            <div className="row position-relative">
              <img className="event-img mt-2" src={process.env.REACT_APP_SHOW_IMAGE_PATH + props.episode.episodelogo} alt="KF Event Logo" />
              <div className="col pe-5">
                <div className="subheading-sm"> {props.episode.title}</div>
                <div className="section-date">{dateFormat(props.episode.dateofevent, "dddd, dS mmmm yyyy")}</div>
              </div>
              {props.user && (props.user.admin || props.user.moderator) ? (
                <button className="btn btn-outline-secondary btn-sm edit-button me-2 mt-1" onClick={() => ShowEditEventForm()}>
                  <FontAwesomeIcon icon="edit" />
                </button>
              ) : null}
            </div>
          </div>

          {showEditBetEventForm ? (
            <EditEpisode
              key={"BetEvent" + props.episode.id}
              episode={props.episode}
              handleSaveChanges={(e) => {
                props.updateEpisode(e);
                setShowEditBetEventForm(false);
              }}
              handleCancelEdit={() => setShowEditBetEventForm(false)}
            />
          ) : null}
          <div className="section-main pb-0">
            <div id={"event-" + props.episode.id}>
              {isLoading ? (
                <LoadingBet />
              ) : bets && bets.length > 0 && !isLoading ? (
                bets.map((b) => <Bet key={b.id} bet={b} user={props.user} updateBet={UpdateBet} />)
              ) : (!isLoading && (!bets || bets?.length === 0)) ? (
                <div className="row no-bets">
                  <div className="col-12 font-italic text-center">No bets for this event yet.</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="section-footer border-top">
            {props.user && (props.user.moderator || props.user.admin) ? (
              <div className="row">
                {showNewBetForm ? (
                  <div className="col-12 mt-2">
                    <NewBetForm handleCloseForm={() => setShowNewBetForm(false)} addNewBet={AddNewBet} eventId={props.episode.id} user={props.user} />
                  </div>
                ) : (
                  <div className="col-12 col-sm-6 col-md-4 mx-auto text-center">
                    <button className="btn btn-outline-secondary btn-sm " onClick={() => ShowNewBetForm()}>
                      <FontAwesomeIcon icon="plus" /> Add bet
                    </button>
                  </div>
                )}
              </div>
            ) : (!props.user) ?
              <div className="bet-menu col-12 px-0">
                <div className="row">
                  <div className="col" title="Like Bet">
                    <Link className={"btn btn-outline-light"} to="/Login">
                      <FontAwesomeIcon icon="right-to-bracket" className={""} /> Login
                    </Link>
                  </div>
                  <div className="col" title="View comments">
                    <Link className={"btn btn-outline-light"} to="/Register">
                      <FontAwesomeIcon icon="user-plus" className={"me-1"} /> Register
                    </Link>
                  </div>
                </div>
              </div>
              : null}
          </div>
        </div>
      </div>
      {/* <NewBetForm user={props.user} /> */}
    </div >
  );
}
