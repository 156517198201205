export default function GetTheme() {
    let theme = GetCookie("theme");

    // Default to dark theme
    if (!theme || theme === "undefined" || theme === "") {
        theme = "dark";
    }
    return theme;
}


export function ChangeTheme(newTheme) {
    const htmlTag = document.querySelector('html');
    htmlTag.setAttribute('data-theme', newTheme);
    SetCookie("theme", newTheme);
}


export function SetCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function GetCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            //console.log("SUBSTRING: ", c.substring(name.length, c.length))
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
