// Imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login(props) {
  //const [errorMessages, setErrorMessages] = useState(null);
  const [loginEmail, setLoginEmail] = useState(null);
  const [loginPassword, setLoginPassword] = useState(null);
  const [message, setMessage] = useState(null);
  const [loginError, SetLoginError] = useState(false)

  async function Login(e) {
    e.preventDefault();

    setMessage("Logging in...");
    SetLoginError(false);
    await Axios({
      method: "POST",
      data: {
        email: loginEmail,
        password: loginPassword,
      },
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/auth/login",
    })
      .then((res) => {
        if (res.status !== 200) {
          console.log("There was an issue authenticating.");
          SetLoginError(true)
          setMessage("There was an issue logging in, please try again.")
        } else {
          setMessage("Success, redirecting now...")
          window.location.href = "/";
        }
      })
      .catch((err) => {
        SetLoginError(true);
        if (err.response?.status === 400) {
          setMessage("Incorrect email or password.");
        } else {
          setMessage("There was an issue login in.");
        }
      });
  };

  function ForgotPassword() {
    alert("Reset password feature coming soon.")
  }
  return (
    <form id="login-form" className="container" onSubmit={(e) => Login(e)}>
      <div id="page-title">PizzaBets</div>
      <div className="row">
        <div className="col-12 px-0">
          {message ?
            //message?.map(e =>
            <div className={"alert alert-" + (loginError ? "warning" : "success") + " login-message"} role="alert">
              <FontAwesomeIcon icon={loginError ? "exclamation-circle" : "circle-check"} className="me-2" />{message}</div>
            //)
            : null}
          {/* {loginSuccess ? (
            <div className="alert alert-success mx-0 login-message" role="alert">
              <FontAwesomeIcon icon={"circle-check"} className="me-2" />
              You have successfully logged in! Redirecting now...
            </div>
          ) : null} */}

          <div className="section">
            <div className="section-content mx-sm-3">
              <div className="section-title">
                <div className="subheading">Log in</div>
              </div>
              <div className="section-main">
                <div className="form-floating mb-4">
                  <input type="email" className="form-control" placeholder="janedoe@email.com" autoComplete="true" defaultValue={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} required />
                  <label className="floatingInput">Email</label>
                </div>

                <div className="form-floating mb-3">
                  <input type="password" className="form-control" autoComplete="off" placeholder="********" defaultValue={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} required />
                  <label className="floatingInput">Password</label>
                  <button type="button" onClick={(e) => ForgotPassword(e)} className="btn btn-link btn-sm mb-2">Forgot Password?</button>
                </div>

                <button type="submit" className="btn btn-light btn-lg w-100">LOG IN</button>

                <hr className="my-4" />
                <div className="small mb-2">
                  Don't have an account?
                  <Link to={"/Register"} className="btn btn-outline-light bold border-0 ms-2 px-2 py-1 pt-0 text-uppercase">Register</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;
