

import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dateFormat from "dateformat";
//import DatePicker from 'react-date-picker';
import TextareaAutosize from 'react-textarea-autosize';



function NewBetForm(props) {


    let todaysDate = dateFormat(new Date(), "yyyy-mm-dd");

    const { handleCloseForm, addNewBet } = props;

    const [bettors, setBettors] = useState([]);
    //const [eventTypes, setEventTypes] = useState([]);
    const [betBy, setBetBy] = useState()
    const [betAgainst, setBetAgainst] = useState(null)
    const [betDescription, setBetDescription] = useState("test")
    const [winningPrize, setWinningPrize] = useState("pizza")
    const [dueDate, setDueDate] = useState(todaysDate)
    //const [formError, setFormError] = useState(todaysDate)


    useEffect(() => {
        LoadTab();
    }, []);




    async function LoadTab() {
        await getBettors();
    }

    const getBettors = () => {
        Axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_URL + "/getbettors",
        }).then((res) => {
            setBettors(res.data);
            setBetBy(res.data[0].id)
        });
    }

    const CreateBet = () => {
        let data = {
            eventId: props.eventId,
            betBy,
            betAgainst: (betAgainst === 0) ? null : betAgainst,
            betDescription,
            winningPrize,
            dueDate,
            createdBy: props.user.id
        }

        Axios({
            method: "POST",
            withCredentials: true,
            url: process.env.REACT_APP_API_URL + "/bets/createnew",
            data: data
        }).then((res) => {
            console.log("blah")
            if (res.status === 200) {
                console.log("Successfully created bet: ", res.data);
                addNewBet(res.data)
                handleCloseForm();
            } else {
                alert("Issue saving data")
            }

        }).catch((err) => {
            console.log("ERROR" + err)
        });
    }


    return (

        <div id="NewBetForm" className="row bet-new">
            <div className="subheading">Add New Bet</div>
            <div className="col-12 col-md-6 mb-2">
                <div className="form-floating">
                    <select className="form-control" name="BetBy" onChange={e => setBetBy(e.target.value)} >
                        <option disabled>Select Bettor</option>
                        {bettors.map(c =>
                            <option key={c.id} value={c.id}>{c.firstname} {c.lastname}</option>
                        )}
                    </select>
                    <label className="floatingInput">Bet By: </label>
                </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
                <div className="form-floating">
                    <select className="form-control" name="BetBy" onChange={e => setBetAgainst(e.target.value)}>
                        <option disabled>Select Bettor ({betAgainst})</option>
                        <option value={0}>No One (General Bet)</option>
                        {bettors.map(c =>
                            <option key={c.id} value={c.id} >{c.firstname} {c.lastname}</option>
                        )}
                    </select>
                    <label className="floatingInput">Bet Against: </label>
                </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
                <div className="form-floating">
                    <TextareaAutosize className="form-control" name="Description" placeholder="What is the bet?" rows="2" onChange={e => setBetDescription(e.target.value)} defaultValue={betDescription} />
                    <label className="floatingInput">What is the bet?</label>
                </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
                <div className="form-floating">
                    <input type="text" className="form-control" name="WinningPrize" rows="3" autoComplete="off" placeholder="E.g. Pizza" defaultValue={winningPrize} onChange={e => setWinningPrize(e.target.value)} />
                    <label className="floatingInput">Winning Prize</label>
                </div>
            </div>

            <div className="col-12 col-md-6">
                <div className="form-floating">
                    <input type="date" className="form-control" name="DueDate" defaultValue={dueDate} onChange={e => { console.log(e.target.value); setDueDate(e.target.value) }} />
                    <label className="floatingInput">Win By Date <FontAwesomeIcon icon={"info-circle"} data-toggle="tooltip" data-placement="top" title="Last date (inclusive) where bet must be won by. E.g. If the bet is a release date of a game, enter in the release date that the bettor believes the game will release on/by." /></label>
                </div>
            </div>

            {/* <div className="form-group">
                <label>Win By Date STYLED <span className="fas fa-info-circle" data-toggle="tooltip" data-placement="top" title="Last date (inclusive) where bet must be won by. E.g. If the bet is a release date of a game, enter in the release date that the bettor believes the game will release on/by."></span></label>
                <DatePicker className="form-control" onChange={(e) => { console.log(e.target.value); setDueDate(e.target.value) }} value={new Date()} />
            </div> */}

            <div className="form-group col-12 col-md-6 mt-2 text-right">
                <button type="button" className="btn btn-danger mx-1"
                    onClick={handleCloseForm}>
                    <FontAwesomeIcon icon="times" /> Cancel
                </button>
                <button type="button" onClick={() => CreateBet()} className="btn btn-success mx-1">
                    <FontAwesomeIcon icon="save" /> Save
                </button>
            </div>
        </div>
    );
}

export default NewBetForm;

