import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Episode from "../components/episodes/episode";
import LoadingBet from "../components/loadingEvent";
import NewEpisodeForm from "../components/episodes/newEpisodeForm";

function Group(props) {

  useEffect(() => {

  }, []);

  return (
    <div className="container">
      <div id="page-title">Groups</div>

      <div className="row my-2">
        <div className="col-12">
          <div className="alert alert-info">
            <h4><FontAwesomeIcon icon="info-circle" /> Groups, coming soon!</h4>
            <ul>
              <li>Form your own group, add your own shows/episodes, control your group bettors, bets/predictions and more</li>
              <li>As a user, you can follow other groups and comment and interact with other users about that groups bets and predictions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Group;
