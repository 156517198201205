import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Episode from "../components/episodes/episode";
import LoadingBet from "../components/loadingEvent";
import NewEpisodeForm from "../components/episodes/newEpisodeForm";

function Group(props) {
  const [errorMessages, setErrorMessages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showBetEventForm, setShowBetEventForm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [group] = useState(props);
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  //const [searchParams, setSearchParams] = useSearchParams();
  const { groupId } = useParams();

  useEffect(() => {

    LoadTab();

  }, []);

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setIsLoading(true);
      getBetEvents();
    } else if (searchText.length === 0) {
      getBetEvents();
    }
  }, [searchText]);

  async function GetGroup() {
    await Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/group/" + groupId,
    }).then((res) => {
      let data = res.data;
      console.log(data)
      setIsLoading(false);
    }).catch(err => {
      alert("Err getting Group")
      window.location.href = "/";
    });
  }
  async function LoadTab() {
    await getBetEvents();
  }

  const getBetEvents = async () => {
    await Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/betevents?searchText=" + searchText,
    }).then((res) => {
      let data = res.data;
      setEpisodes(data);
      setIsLoading(false);
    });
  };

  // const searchBets = async () => {
  //   await Axios({
  //     method: "POST",
  //     withCredentials: true,
  //     url: process.env.REACT_APP_API_URL + "/bets/search",
  //     data: { searchText }
  //   }).then((res) => {
  //     let data = (res.data)
  //     setBetEvents(data);
  //     setIsLoading(false);
  //   });
  // };

  const AddNewEvent = async () => {
    await Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/betevents",
      data: { searchText },
    }).then((res) => {
      let data = res.data;
      setEpisodes(data);
      setIsLoading(false);
    });
  };

  const UpdateEpisode = async (e) => {
    // prettier-ignore
    await setEpisodes((rows) =>
      rows.map((r) =>
        r.id === e.id
          ? {
            ...r,
            description: e.newDescription,
            title: e.newTitle,
            eventDate: e.newEventDate,
            eventTypeId: e.newEventTypeId,
            episodelogo: e.newImageUrl,
          }
          : r
      )
    );
  };

  const UpdateEventsList = async (e) => {
    console.log("Add Episode:");
    let newEvent = await getEventSingle(e);
    if (newEvent) {
      await setEpisodes((events) => [newEvent, ...events]);
    }
    setShowBetEventForm(false);
  };

  const getEventSingle = async (eventId) => {
    try {
      return await Axios({
        method: "post",
        withCredentials: true,
        url: process.env.REACT_APP_API_URL + "/betevent",
        data: { eventId },
      }).then((res) => {
        return res.data[0];
      });
    } catch (error) {
      console.log("Catch: getEventSingle", error);
      return null;
    }
  };

  return (
    <div className="container">
      <div id="page-title">KF-Bets</div>

      <div className="row">
        <div className="input-group col-12 rounded">
          <input type="text" className="form-control rounded-top-right rounded-bottom-right" placeholder="Type 3 or more characters to search" value={searchText} onChange={(e) => setSearchText(e.target.value)} aria-label="Search" />

          {!isLoading && (props.user?.admin || props.user?.moderator) ? (
            <button className="btn btn-outline-success rounded ms-1" onClick={() => setShowBetEventForm(!showBetEventForm)}>
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : null}
        </div>
      </div>

      {showBetEventForm ? <NewEpisodeForm handleCancelNewEvent={() => setShowBetEventForm(!showBetEventForm)} updateEventsList={UpdateEventsList} /> : null}
      {isLoading ? <LoadingBet key={"LoadingBet"} /> : null}

      {episodes && episodes.length > 0 ? (
        <div className="betevents row mt-2">
          {episodes.map((e) => (
            <Episode key={e.id} episode={e} user={props.user} updateBetEvent={UpdateEpisode} searchText={searchText} />
          ))}
        </div>
      ) : !isLoading ? (
        <div className="row">
          <div className="col-12">
            <div className="section">
              <div className="section-content">
                <div className="subheading text-center">Uh Oh! No results were found</div>
                <div className="section-main text-center">
                  <FontAwesomeIcon icon="face-meh" className="white fa-4x" />
                  <div className="mt-2">Please refine your search</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Group;
