import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function NotFound(props) {

  useEffect(() => {

  }, []);

  return (
    <div className="container">


      <div className="row">
        <div className="col-12">

          <div className="section">
            <div className="section-content">
              <div className="section-title subheading px-2">
                <div id="subheading">Oops!</div>
              </div>
              <div className="section-main text-center">
                <h2>404 - Page Not Found</h2>
                <div className="error-details">
                  Looks like the page your trying to see doesn't exist or has moved.
                </div>
                <div className="my-3">
                  <Link className="btn btn-primary btn-lg" to="/"><FontAwesomeIcon icon="home" /> Home</Link>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default NotFound;