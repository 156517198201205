
import { Link } from "react-router-dom";

export default function Privacy(props) {

  return (
    <div className="container">
      <div id="page-title">Privacy</div>
      <div className="container">
        <div className="row">
          <div className="col-12">

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Privacy Policy</div>
                </div>
                <div className="section-main">
                  <ol>
                    <li>TEst</li>
                  </ol>
                  <div>KF-Bets is not affiliated with Kinda Funny and does not refelct views or opinions of Kinda Funny or anoyone officially involved in producing or managing Kinda Funny content.</div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Payment Information</div>
                </div>
                <div className="section-main">
                  <ol>
                    <li>Online payments through our website are powered by Stripe. When you make a purchase through our Website and make payments to us, Stripe (our third-party payment gateway) will collect and host all personal information necessary to complete the transaction,
                      including your name, email, and credit card/debit card information. Stripe's privacy policy can be viewed here - <Link to="https://stripe.com/au/privacy" className="btn btn-link p-0 m-0">https://stripe.com/au/privacy</Link></li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Terms</div>
                </div>
                <div className="section-main">
                  <ol>
                    <li>Please refer to our <Link to="/Terms" className="btn btn-link p-0 m-0">Terms</Link> for our Terms of Use policy.</li>
                  </ol>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  );
}

