import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function About(props) {

  useEffect(() => {
    LoadTab();
  }, []);

  async function LoadTab() {

  }

  return (
    <div className="container px-0">
      <div id="page-title">About</div>



      <div className="row">
        <div className="col-12">

          <div className="section">
            <div className="section-content">
              <div className="section-title subheading px-2">
                <div id="subheading">What is KF-Bets</div>
              </div>
              <div className="section-main">
                <ul className="text-left">
                  <li>A place to find all the fun, silly and serious game predictions and pizza bets the Kinda Funny Crew
                    make against eachother.</li>
                  <li>This is a fan made project made by a KFBF for the KFBFs</li>
                  <li>This site is moderated by some of the KFBS's too.</li>
                </ul>
                <div className="ml-2">
                  <i>If you would like to support keep KF-Bets live, feel free to</i> <a className="btn btn-outline-warning btn-sm" target={"_blank"} rel={"noreferrer"} href="https://buymeacoffee.com/dkara"><FontAwesomeIcon icon="coffee" /> Buy Me a Coffee</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="section-content">
              <div className="section-title subheading px-2">
                <div id="subheading">Disclaimer</div>
              </div>
              <div className="section-main">
                <div>KF-Bets is not affiliated with Kinda Funny and does not refelct views or opinions of Kinda Funny or anoyone officially involved in producing or managing Kinda Funny content.</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
}

export default About;