// Imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import dateFormat from "dateformat";

// Scripts
import { UploadImageByUri, GenerateAvatar } from "../scripts/upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Register() {
  const [errorMessages, setErrorMessages] = useState([]);
  const [registerEmail, setRegisterEmail] = useState(null);
  const [registerFirstName, setRegisterFirstName] = useState(null);
  const [registerLastName, setRegisterLastName] = useState(null);
  const [registerPassword, setRegisterPassword] = useState(null);
  const [confirmRegisterPassword, setConfirmRegisterPassword] = useState(null);

  async function register() {
    if (process.env.REACT_APP_REGISTRATION_DISABLED) {
      alert("Registration is currently disabled.")
      return null
    }

    Axios({
      method: "POST",
      data: {
        RegisterFirstName: registerFirstName,
        RegisterLastName: registerLastName,
        RegisterEmail: registerEmail,
        RegisterPassword: registerPassword,
        confirmRegisterPassword: confirmRegisterPassword,
      },
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/auth/register",
    })
      .then(async (res) => {
        console.log("POST: /register", res);
        if (res.status === 200) {
          // Generate default initials avatar and upload as users image
          let dateUTC = new Date().toUTCString();
          let now = dateFormat(dateUTC, "yyyy-mm-dd_hh:MM:ss");
          let fileName = "test_" + now + ".png"
          let newImage = await GenerateAvatar("initials", registerFirstName + " " + registerLastName);
          var upload = await UploadImageByUri(newImage, "profile", fileName, res.data.id);
          if (upload.success) {
            UpdateUserImagePath(fileName, res.data.id);
          } else {
            console.log("Failed to upload:", upload.message);
          }
        }
        window.location.href = "/login";
      })
      .catch((res) => {
        console.log("POST: /register -- err", res);
      });
  };

  const UpdateUserImagePath = (fileName, userId) => {

    Axios({
      method: "POST",
      data: { fileName, userId },
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/account/updateuserimage"
    })
      .then((res) => {
        return (res.status === 200 ?? false)
      }).catch((err) => {
        return false;
      });
  }

  return (
    <div id="register-form" className="container">

      <div id="page-title">PizzaBets</div>


      {errorMessages?.length > 0 ? (
        <ul>
          {errorMessages.map((e) => (
            <li key={e.param}>{e.msg}</li>
          ))}
        </ul>
      ) : null}

      <div className="row">
        <div className="col-12 mx-auto px-0">
          <div className="section">
            <div className="section-content mx-sm-3">
              <div className="section-title">
                <div className="subheading">Sign Up</div>
              </div>
              <div className="section-main">
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" autoComplete="off" placeholder="First Name" defaultValue={registerFirstName} onChange={(e) => setRegisterFirstName(e.target.value)} />
                  <label className="floatingInput">First Name</label>
                </div>

                <div className="form-floating mb-3">
                  <input type="text" className="form-control" autoComplete="off" placeholder="Last name" defaultValue={registerLastName} onChange={(e) => setRegisterLastName(e.target.value)} />
                  <label className="floatingInput">Last Name</label>
                </div>

                <div className="form-floating mb-3">
                  <input type="text" className="form-control" autoComplete="off" placeholder="Email" defaultValue={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
                  <label className="floatingInput">Email</label>
                </div>

                <div className="form-floating mb-3">
                  <input type="password" className="form-control" autoComplete="off" placeholder="******" defaultValue={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
                  <label className="floatingInput">Password</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    autoComplete="off"
                    placeholder="******"
                    defaultValue={confirmRegisterPassword}
                    onChange={(e) => setConfirmRegisterPassword(e.target.value)}
                  />
                  <label className="floatingInput">Confirm Password</label>
                </div>

                <div className="row">
                  <button className="btn btn-light btn-lg w-100 text-uppercase" onClick={register} > Register </button>
                </div>
                <hr className="my-4" />

                <div className="small mb-2">
                  Already have an account?
                  <Link to={"/Login"} className="btn btn-outline-light bold border-0 ms-2 px-2 py-1 pt-0 text-uppercase">Log in</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
