import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LoadingBet(props) {
  return (
    <div className="col-12 px-0 loading">
      <div className="section">
        <div className="section-content">
          <div className="section-main">
            <div className="row my-2">
              <FontAwesomeIcon icon={"spinner"} className="fa-3x fa-spin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingBet;
