import React, { useState, useEffect } from "react";

//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Users from "../components/admin/users";
import Tools from "../components/admin/tools";

export default function Admin(props) {
    const [errorMessages, setErrorMessages] = useState([]);
    const [visibleSection, setVisibleSection] = useState("Tools");
    const [betEvents, setBetEvents] = useState([]);

    useEffect(() => {
        LoadTab();
    }, []);

    async function LoadTab() { }

    return (
        <div className="container">

            <div className="row mt-2 pt-2 border-dark">
                <div className="col-12">
                    <button type="text" className={"btn btn-" + (visibleSection === "Users" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Users")}>
                        Users
                    </button>
                    <button type="text" className={"btn btn-" + (visibleSection === "Groups" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Groups")}>
                        Groups
                    </button>
                    <button type="text" className={"btn btn-" + (visibleSection === "Reports" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Reports")}>
                        Reports
                    </button>
                    <button type="text" className={"btn btn-" + (visibleSection === "Stats" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Stats")}>
                        Stats
                    </button>
                    <button type="text" className={"btn btn-" + (visibleSection === "Tools" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Tools")}>
                        Tools
                    </button>
                </div>
            </div>

            <div id="page-title">
                Admin - {visibleSection}
            </div>

            {visibleSection === "Users" ?
                <Users key={"admin-users"} user={props.user} />
                : (visibleSection === "Tools") ?
                    <Tools key={"admin-tools"} user={props.user} />
                    : <div className="row">
                        <div className="col-12">
                            <div className="alert alert-info">
                                <h4><FontAwesomeIcon icon="exclamation-circle" /> Coming soon!</h4>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

