import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { validateImage } from "image-validator";
import { UploadImageByUri, GenerateAvatar } from "../../scripts/upload";
const { v4: uuidv4 } = require('uuid');

export default function NewShow(props) {
  const { handleCloseForm, addNewBet } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageObject, setImageObject] = useState(() => GetNewAvatar());
  const [errorList, setErrorList] = useState([]);
  const [loading, setLoading] = useState(false);


  async function GetNewAvatar() {
    let newAvatar = await GenerateAvatar("shapes");
    setImageObject(newAvatar);
  }

  async function CreateEventType(e) {
    e.preventDefault();
    setLoading(true);

    let dateUTC = new Date().toUTCString();
    let now = dateFormat(dateUTC, "yyyy-mm-dd_hh:MM:ss");
    let fileName = title + "-" + uuidv4() + "_" + now + ".png";

    let data = {
      title,
      description,
      imageURL: fileName,
    };

    let tmpErrors = [];

    if (title === "") {
      tmpErrors.push("Title name must not be blank");
    }
    if (description === "") {
      tmpErrors.push("Description name must not be blank");
    }

    let isValidImage = await validateImage(imageObject);
    if (!isValidImage) {
      console.log("The media resource is either invalid, corrupt or unsuitable");
      return null;
    } else {
      console.log("Image okay.");
      var upload = await UploadImageByUri(imageObject, "shows", fileName);
      if (!upload.success) {
        // console.log("Failed to upload", upload.message);
        tmpErrors.push("Image file size exceeds 2MB limit.");
      }
    }

    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setLoading(false);
      return;
    }
    await Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/createnewtype",
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          handleCloseForm()
          addNewBet();
        } else {
          alert("Issue saving data");
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log(err.response.data);
        } else if (err.response.status === 401 || err.response.status === 403) {
          window.location.href = "/";
        }
      });
    setLoading(false);
  }

  return (
    <form className="section editform-active" action="#" onSubmit={(e) => CreateEventType(e)}>
      <div className="section-content">
        <div className="section-main">
          <div id="NewBetForm" className="row">
            <div className="subheading-sm">Add New Event/Show</div>

            <div className="col-12 col-sm-7 col-md-8 col-lg-9 px-1">
              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="Show Title" rows="2" onChange={(e) => setTitle(e.target.value)} defaultValue={title} />
                <label className="floatingInput">Title</label>
              </div>

              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="Show Description" rows="2" onChange={(e) => setDescription(e.target.value)} defaultValue={description} />
                <label className="floatingInput">Descritpion</label>
              </div>
              <button type="button" className="btn btn-link btn-sm mx-1 mb-0" title="Create" disabled={loading} onClick={() => GetNewAvatar()}>
                <FontAwesomeIcon icon="refresh" /> Generate New Image
              </button>

            </div>

            <div className="col-12 col-sm-5 col-md-4 col-lg-3 px-1 px-0 mb-2 text-center">
              <img src={imageObject} className="img-preview img-fluid border border-1 rounded-circle " alt={"Bettor preview"} />

              <div className="form-group mt-4 text-right">

                <button type="button" className="btn btn-danger mx-1" onClick={handleCloseForm} title="Cancel" disabled={loading}>
                  <FontAwesomeIcon icon="times" /> Cancel
                </button>

                <button type="submit" className="btn btn-success mx-1" title="Create" disabled={loading}>
                  {loading ?
                    <FontAwesomeIcon icon="spinner" className="fa-spin" />
                    :
                    <FontAwesomeIcon icon="save" />
                  } Create
                </button>
              </div>
            </div>

            <div className="col-12 px-0">

              {errorList.length > 0 ? (
                <ul className="alert alert-danger px-4" role={"alert"}>
                  {errorList.map((e) => (
                    <li key={e} className="">
                      {e}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>


          </div>
        </div>
      </div>
    </form>
  );
}

