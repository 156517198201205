

import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dateFormat from "dateformat";
import DatePicker from 'react-date-picker';
import TextareaAutosize from 'react-textarea-autosize';

const EditBet = (props) => {
  const { handleCancelEdit } = props;

  const [bettors, setBettors] = useState([]);
  const [betBy, setBetBy] = useState(props.bet.betby)
  const [betAgainst, setBetAgainst] = useState(props.bet.betagainst)
  const [betDescription, setBetDescription] = useState(props.bet.description)
  const [winningPrize, setWinningPrize] = useState(props.bet.winningprize)
  const [dueDate, setDueDate] = useState(dateFormat(props.bet.duedate, "yyyy-mm-dd"))
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState(null);
  useEffect(() => {
    //console.log("EDITBET: " + JSON.stringify(props.bet))

    // setDueDate(dateFormat(props.bet.duedate, "mm/dd/yyyy"))
    LoadTab();

  }, []);

  const SaveBet = () => {
    setIsLoading(true);
    let tmpErrors = [];

    if (betBy === betAgainst) {
      tmpErrors.push("Bettor cannot bet against themselves.")
    }

    if (tmpErrors.length > 0) {
      setMessages(tmpErrors)
      setIsLoading(false);
      return false;
    }

    let updatedBedData = {
      id: props.bet.id,
      newBetBy: betBy,
      newBetAgainst: betAgainst,
      newBetDescription: betDescription,
      newWinningPrize: winningPrize,
      newDueDate: dueDate
    }

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/savebet",
      data: updatedBedData,
    }).then((res) => {
      if (res.status === 200) {
        props.updateBet(updatedBedData.id);
      }
    });
  };

  async function LoadTab() {
    await getBettors();
  }


  const getBettors = () => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/getbettors",
    }).then((res) => {
      setBettors(res.data);
    });
  }

  return (

    <div className="row bet-edit">

      <div className="subheading">Edit</div>

      <div className="col-12 col-md-6 mb-2">
        <div className={"form-floating"}>
          <select className="form-control" defaultValue={props.bet.betby} name="BetBy" onChange={e => setBetBy(e.target.value)}>
            <option>{props.bet.betbyname} [CURRENT]</option>
            {bettors.map(c =>
              <option key={c.id} value={c.id}>{c.firstname} {c.lastname}</option>
            )}
          </select>
          <label className="floatingInput"><FontAwesomeIcon icon={"chevron-down"} /> Bet By</label>
        </div>
      </div>

      <div className="col-12 col-md-6 mb-2">
        <div className={"form-floating"}>
          <select className="form-control" name="BetBy" onChange={e => setBetAgainst(e.target.value)}>
            {
              props.bet.betagainst
                ? <option defaultValue={props.bet.betagainst}>{props.bet.betagainstname} [CURRENT]</option>
                : <option defaultValue={0}>No One (General Bet) [CURRENT]</option>
            }
            <option value={0}>No One (General Bet)</option>
            {bettors.map(c =>
              <option key={c.id} value={c.id}>{c.firstname} {c.lastname}</option>
            )}
          </select>
          <label className="floatingInput"><FontAwesomeIcon icon={"chevron-down"} /> Bet Against</label>
        </div>
      </div>


      <div className="col-12 mb-2">
        <div className={"form-floating"}>
          <TextareaAutosize className="form-control" name="Description" placeholder="What is the bet?" rows={4} onChange={e => setBetDescription(e.target.value)} defaultValue={betDescription} />
          <label className="floatingInput">What is the bet</label>
        </div>
      </div>


      <div className="col-12 col-md-6 mb-2 ">
        <div className="form-floating">
          <input type="text" className="form-control" name="WinningPrize" rows="3" autoComplete="off" placeholder="E.g. Pizza" defaultValue={winningPrize} onChange={e => setWinningPrize(e.target.value)} />
          <label className="floatingInput">Winning Prize</label>
        </div>
      </div>

      <div className="col-12 col-md-6  mb-2">
        <div className="form-floating">
          <input type="date" className="form-control" name="DueDate" onChange={e => setDueDate(e.target.value)} defaultValue={dueDate} />
          {/* <DatePicker onChange={setDueDate} value={dueDate} />  */}
          <label className="floatingInput">Win By Date (dd/mm/yyy) <span className="fas fa-info-circle" data-toggle="tooltip" data-placement="top" title="Last date (inclusive) where bet must be won by. E.g. If the bet is a release date of a game, enter in the release date that the bettor believes the game will release on/by."></span></label>
        </div>
      </div>

      {messages?.length > 0 ? (
        <div className="col-12 mb-2">
          <div className="alert alert-info">
            <ul className="mb-0">
              {messages.map((m, i) => (
                <li key={"editBet-" + i}>{m}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}

      <div className="col-12 mt-2 mb-2">
        <div className="form-group text-right ">
          {isLoading ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
          <button type="button" className="btn btn-danger mx-1" onClick={handleCancelEdit} disabled={isLoading}>
            <FontAwesomeIcon icon="times" /> Cancel
          </button>

          <button type="button" className="btn btn-success mx-1" onClick={() => SaveBet()} disabled={isLoading}>
            <FontAwesomeIcon icon="save" /> Save
          </button>
        </div>
      </div>
   
    </div>


  );
}

export default EditBet;

