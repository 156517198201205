import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import DatePicker from "react-date-picker";
import TextareaAutosize from "react-textarea-autosize";

function NewEpisodeForm(props) {
  const [eventTypesList, setEventTypesList] = useState([]);
  const [eventType, setEventType] = useState("");
  const [title, setTitle] = useState("test title");
  const [description, setDescription] = useState("test desc");
  const [eventDate, setEventDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setEventDate(dateFormat(new Date(), "yyyy-mm-dd"));
    LoadTab();
  }, []);

  const CreateBetEvent = (e) => {
    e.preventDefault();
    setIsLoading(true);

    var newEvent = {
      newEventTypeId: eventType,
      title,
      description,
      eventDate,
    };

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/newBetEvent",
      data: newEvent,
    }).then((res) => {
      props.updateEventsList(res.data[0].id);
    });
    setIsLoading(false);

  };

  async function LoadTab() {
    await getEventTypes();
  }

  const getEventTypes = async () => {
    await Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/geteventtypes",
    }).then((res) => {
      setEventTypesList(res.data);
      setEventType(res.data[0].id);
    });
    setIsLoading(false);
  };

  return (
    <form action="#" id="NewBetForm" onSubmit={(e) => CreateBetEvent(e)}>
      <div className="col-12">
        <div className="section editform-active">
          <div className="section-content">
            <div className="section-main row">
              <div className="subheading-sm px-2">New Episode</div>

              <div className="col-12 col-md-6">
                <div className="form-floating mb-2">
                  <select className="form-control" name="EventType" defaultValue={eventType} onChange={(e) => setEventType(e.target.value)} required>
                    <option value={0} disabled>
                      Choose Event Type
                    </option>
                    {eventTypesList.map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.title} - {e.description}
                      </option>
                    ))}
                  </select>
                  <label className="floatingInput">Show</label>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-floating mb-2">
                  <input type="text" className="form-control" name="EventTitle" placeholder="Episode Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  <label className="floatingInput">Title</label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-floating mb-2">
                  <TextareaAutosize className="form-control" name="EventDescription" placeholder="Episode description" rows="2" value={description} onChange={(e) => setDescription(e.target.value)} />
                  <label className="floatingInput">Description</label>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-floating mb-2">
                  <input type="date" className="form-control" name="EventDate" value={eventDate} onChange={(e) => setEventDate(e.target.value)} />
                  <label className="floatingInput">Event Date</label>
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="form-group text-right">
                  {isLoading ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
                  <button type="button" className="btn btn-danger mx-2" onClick={props.handleCancelNewEvent} disabled={isLoading}>
                    <FontAwesomeIcon icon="times" /> Cancel
                  </button>
                  <button type="submit" className="btn btn-success" disabled={isLoading}>
                    <FontAwesomeIcon icon="save" /> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default NewEpisodeForm;
