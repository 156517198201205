import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateImage } from "image-validator";
import Cropper from "react-easy-crop";
import getCroppedImg from "../scripts/cropImage";

import { createClient } from "@supabase/supabase-js";
// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
// const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
// const supabase = createClient(supabaseUrl, supabaseKey);

export default function CropView(props) {
  const [errorList, setErrorList] = useState([]);
  const [imageFile] = useState(props.file);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageFile, croppedAreaPixels);
      props.handleApplyCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div className="col-12 mt-3">
      <div id="crop-modal">
        <div className="subheading">CROP</div>
        <div id="profile-cropper" className="col-12 border rounded py-2 position-relative">
          <Cropper
            image={props.file}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape={"round"}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            classes={"form-control rounded-circle border"} />
        </div>
        <div className="mt-2 text-right br-black">
          <input type="range" className="form-range" min="1" max="3" defaultValue={zoom} step={0.1} onChange={(e) => setZoom(e.target.value)} />
          <button type="button" onClick={props.handleCancelCrop} className="btn btn-danger me-2">
            <FontAwesomeIcon icon="times" /> Cancel
          </button>
          <button type="button" onClick={showCroppedImage} className="btn btn-success ">
            <FontAwesomeIcon icon="save" /> Apply
          </button>
        </div>
      </div>
    </div>
  );
}
