// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// Components
import EditBettor from "./editBettor";

function Bettor(props) {
  const [errorMessages, setErrorMessages] = useState(null);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [editModalClass, setEditModalClass] = useState(null);
  const [imageSource, setImageSource] = useState((!props.bettor.imagepath.startsWith("data:image/svg+xml;") ? process.env.REACT_APP_BETTOR_IMAGE_PATH : "") + props.bettor.imagepath)

  // const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setEditModalClass(editModeEnabled ? " editform-active" : "");
  }, [editModeEnabled]);

  const handleCancelEdit = () => {
    setEditModeEnabled(false);
  };

  const updateBettor = (bettorData) => {
    props.updateBettor(bettorData);
    setEditModeEnabled(false);
  };

  return (
    <div className="col-12 col-md-6 px-0 px-md-1">
      <div className={"section " + editModalClass}>
        <div className="section-content">
          <div className="section-main">
            <div className="row position-relative ">
              <img className="bettor-img" src={imageSource} alt="KF logo" />
              <div className="col pe-5">
                <div className="bettor-name">{props.bettor.firstname + " " + props.bettor.lastname}</div>
                <div className="bettor-alias">{props.bettor.alias}</div>
              </div>
              <button className="col d-inline-block btn btn-outline-secondary btn-sm edit-button" title="Edit bettor info" onClick={() => setEditModeEnabled(!editModeEnabled)}>
                <FontAwesomeIcon icon="edit" />
              </button>
            </div>
            {editModeEnabled ? <EditBettor key={"editbettor-" + props.bettor.id} bettor={props.bettor} handleCancelEdit={handleCancelEdit} updateBettor={updateBettor} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bettor;
