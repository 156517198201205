// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from "axios";

// Components
import ShowInfo from "./ShowInfo";
import NewEventForm from "./newShow";
import Loading from "../loadingEvent";


export default function Shows(props) {

  const [errorMessages, setErrorMessages] = useState(null)
  const [Shows, setShows] = useState([])
  const [showNewShowForm, setShowNewShowForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    LoadData();
  }, []);

  async function LoadData() {
    await Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/getEventTypes",
    }).then((res) => {
      if (res.data[0]) {
        setShows(res.data)
        setIsLoading(false)
      } else {
        setErrorMessages(res.data[1].errors)
      }
    });
    setIsLoading(false)
  }

  const updateEvent = (eventData) => {
    setShows(rows => rows.map((e) => (e.id === eventData.id ? eventData : e)))
  };

  function ToggleNewShowForm() {
    if(Shows?.length <= 10) {
      setShowNewShowForm(!showNewShowForm)
    } else {
      alert("Show limit of 10 has been reached.")
    }
  }

  return (
    <div className="row">
      <div className="subheading">Shows
          <button className="btn btn-outline-success btn-sm pull-right" title="Add new show" hidden={isLoading} onClick={() => ToggleNewShowForm()} >
            <FontAwesomeIcon icon="plus" /> New ({Shows?.length} / 10)
          </button>
      </div>

      {(showNewShowForm && Shows.length <= 10) ?
        <NewEventForm key={"AddNewBettor"} user={props.user} handleCloseForm={() => setShowNewShowForm(false)} addNewBet={() => LoadData()} />
        : null}

      {isLoading ?
        <Loading />
        : (!isLoading && Shows.length > 0) ?
          Shows.map(e =>
            <ShowInfo key={"showinfo" + e.id} e={e} user={props.user} updateEvent={updateEvent} />
          )
          : null }
      <hr className="my-2" />
    </div>

  );
}


