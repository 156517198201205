import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { validateImage } from "image-validator";

// Scripts
import { UploadImageByUri, GenerateAvatar } from "../../scripts/upload";

// Components
import CropView from "../CropView";

export default function EditProfile(props) {
  const [firstName, setFirstName] = useState(props.user.firstname);
  const [lastName, setLastName] = useState(props.user.lastname);
  //const [imageFile, setImageFile] = useState(props.user.fullimageurl);
  const [imageObject, setImageObject] = useState(props.user.fullimageurl);
  const [errorList, setErrorList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showCropImage, setShowCropImage] = useState(null);
  const [preCropImageObject, setPreCropImageObject] = useState(null);

  useEffect(() => {
    setImageObject(props.user.fullimageurl);
  }, [props.user]);

  useEffect(() => {
    if (props.imageSelected != null) {
      let img = props.imageSelected;
      console.log(URL.createObjectURL(img));
      setImageObject(URL.createObjectURL(img));
      //setShowCropImage(true);
    }
  }, [props.imageSelected]);


  async function SaveChanges(e) {
    e.preventDefault();

    setErrorList([]);
    let tmpErrors = [];
    setIsSaving(true);

    let fileName = props.user.imageurl;
    if (props.user.fullimageurl !== imageObject) {
      const isValidImage = await validateImage(imageObject);
      if (!isValidImage) {
        tmpErrors.push("The media resource is either invalid, corrupt or unsuitable");
      } else {
        console.log("Image okay.");

        // Upload image
        let dateUTC = new Date().toUTCString();
        let now = dateFormat(dateUTC, "yyyy-mm-dd_hh:MM:ss");
        fileName = props.user.id + "_" + now + ".png";

        var upload = await UploadImageByUri(imageObject, "profiles", fileName);
        if (!upload.success) {
          tmpErrors.push(upload.message);
        }
      }
    }

    if (firstName === "") {
      tmpErrors.push("First name must not be blank");
    }
    if (lastName === "") {
      tmpErrors.push("Last name must not be blank");
    }

    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsSaving(false);
      return;
    }

    let userData = {
      id: props.user.id,
      firstname: firstName,
      lastname: lastName,
      fileName: fileName,
    };

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/updateuser",
      data: userData,
    }).then((res) => {
      console.log("Save result: " + JSON.stringify(res.data));
      if (res.status === 200) {
        props.updateUser(userData);
      } else {
        setErrorList(["There was an issue update the information. Please try again later!"]);
        setIsSaving(false);
      }
    });
  }

  async function GetNewAvatar() {
    let newAvatar = await GenerateAvatar("personas");
    setImageObject(newAvatar);
  }

  async function handleApplyCrop(e) {
    setImageObject(e);
    setShowCropImage(false);
  }

  const handleCancelCrop = () => {
    //setImageObject(props.user.fullimageurl);

    setShowCropImage(false);
    setImageObject(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
  };

  const handleCancelEdit = () => {
    //setImageObject(props.user.fullimageurl);
    setShowCropImage(false);
    setImageObject(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
    props.handleToggleEdit();
  };

  // async function OpenCropImage() {
  //   setSaving(true);
  //   console.log("Open file");
  //   let input = document.createElement("input");
  //   input.type = "file";
  //   input.onchange = async (_) => {
  //     // you can use this method to get file and perform respective operations
  //     console.log("TARGET", input.files);
  //     let imageObject = input.files[0];
  //     //await UploadImage(imageObject); // THIS WORKS
  //     //setImageFile(imageObject);
  //     setImageObject(URL.createObjectURL(imageObject));
  //     //setShowCropImage(true);
  //   };
  //   input.click();
  //   setSaving(false)
  // }

  async function InitiateImageCrop(e) {
    let imgURL = URL.createObjectURL(e.target.files[0])
    console.log("CROPS:", e.target.files[0])
    setPreCropImageObject(imgURL);
    setShowCropImage(true);
  }

  async function handleApplyCrop(e) {
    console.log("Appling crop:", e)
    setImageObject(e);
    setShowCropImage(false);
  }


  return (
    <form action="#" className="is-valid" onSubmit={(e) => SaveChanges(e)}>
      <div className="bettor-edit row">
        <div className="subheading">Edit</div>

        {showCropImage === true ? (
          <div className="col-12">
            <CropView key="CropImage" file={imageObject} handleApplyCrop={(e) => handleApplyCrop(e)} handleCancelCrop={handleCancelCrop} />
          </div>
        ) : null}
        <div className="row px-0">

          <div className="col-12 col-sm-8 mb-2 mb-sm-0 px-0">
            <div className="col-12">
              <div className="form-floating mb-2">
                <input type="text" className={"form-control"} name="Description" placeholder="First Name" rows="2" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                <label className="floatingInput">First Name</label>
              </div>
            </div>

            <div className="col-12">
              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="Last Name" rows="2" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                <label className="floatingInput">Last Name</label>
              </div>
            </div>
          </div>

          <div className="col-4 col-sm-4 px-0">
            <div className="profile-img-container">
              <img className="profile-img" src={imageObject} alt="User" />
              {1 == 2 && props.user.admin ?
                <label htmlFor="file-input" className="upload-icon-container btn btn-outline-light">
                  <FontAwesomeIcon icon="arrow-up-from-bracket" htmlFor="file-input" />
                </label>
                : null}
              <input id="file-input" type={"file"} className="d-none" onChange={(e) => InitiateImageCrop(e)} />
            </div>
            <div className="col-12 text-center mt-1 px-0">
              <button type="button" className="btn btn-outline-warning btn-sm" onClick={() => GetNewAvatar()} >Generate Random</button>
            </div>
          </div>



          <div className="col-8 col-sm-12 d-inline-flex px-1 mt-sm-3">
            <div className="form-group d-flex align-self-end align-items-end ms-auto">
              {isSaving ?
                <div className="">
                  <FontAwesomeIcon icon="spinner" className="fa-spin mb-1 me-2" />
                </div>
                : null}
              <button type="button" className="btn btn-danger mx-1" onClick={handleCancelEdit} disabled={isSaving}>
                <FontAwesomeIcon icon="times" /> Cancel
              </button>
              <button type={"submit"} className="btn btn-success mx-1" onClick={() => SaveChanges} disabled={isSaving}>
                <FontAwesomeIcon icon="save" /> Save
              </button>
            </div>
          </div>

        </div>

        {errorList.length > 0 ? (
          <div className="col-12">
            <ul className="alert alert-danger px-4" role={"alert"}>
              {errorList.map((e) => (
                <li key={e} className="">
                  {e}
                </li>
              ))}
            </ul>
          </div>
        ) : null}


      </div>
    </form >
  );
}
