// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// Components
import User from "./user";
import AddNewBettor from "../newBettor";

export default function Users(props) {
    const [showNewUserForm, setShowNewUserForm] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        LoadData();
    }, []);

    async function LoadData() {
        Axios({
            method: "POST",
            withCredentials: true,
            url: process.env.REACT_APP_API_URL + "/admin/activeusers",
        }).then((res) => {
            //console.log(res)
            if (res.data[0]) {
                setUsers(res.data);
            } else {
                setErrorMessages(res.data[1].errors);
            }
        }).catch(err => {
            console.log("au: ", err)
        });

        //console.log(setUsers.filter((e) => e.status === "Guest"));
    }

    const updateBettor = (bettorData) => {
        setUsers((rows) => rows.map((r) => (r.id === bettorData.id ? bettorData : r)));
    };

    return (
        <div className="row">
            <div className="subheading">
                Users
                <button className="btn btn-outline-success btn-sm pull-right" title="Add new bettor" onClick={() => setShowNewUserForm(!showNewUserForm)}>
                    <FontAwesomeIcon icon="plus" /> New
                </button>
            </div>

            {/* {showNewUserForm === true ? <AddNewBettor key={"AddNewBettor"} user={props.user} handleCloseForm={() => setShowNewUserForm(false)} addNewBettor={(e) => UpdateBettors(e)} /> : null} */}
            {users
                .filter((e) => e.active)
                .map((u) => (
                    <User key={u.id} user={u} updateBettor={updateBettor} />
                ))}
        </div>
    );
}


