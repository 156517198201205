import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
//import DatePicker from 'react-date-picker';

function NewBettorForm(props) {
  let todaysDate = dateFormat(new Date(), "yyyy-mm-dd");

  const { handleCloseForm, addNewBettor } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [alias, setAlias] = useState("");
  const [dob, setDOB] = useState(todaysDate);
  const [status, setStatus] = useState("Full-Time");

  const [formError, setFormError] = useState(null);
  const [imagePath, setImagePath] = useState("https://www.kindafunny.com/wp-content/uploads/2017/12/kinda-funny-logo.png");
  const [errorList, setErrorList] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => { }, []);

  const CreateBettor = () => {
    let data = {
      firstName,
      lastName,
      alias,
      imagePath,
      dob,
    };

    if (data.firstName === "" || data.lastName === "" || data.alias === "") {
      setFormError("Please fill in all fields.");
      return null;
    }

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/Bettors/createnew",
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Successfully created bet: ", res.data);
          addNewBettor(data);
          handleCloseForm();
        } else {
          alert("Issue saving data");
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log(err.response.data);
        } else if (err.response.status === 401 || err.response.status === 403) {
          window.location.href = "/";
        }
      });
  };

  return (
    <div className="section editform-active">
      <div className="section-content">
        <div className="section-main">
          <div className="subheading-sm">Add New Bettor</div>

          <div id="NewBetForm" className="row">
            {formError ? (
              <div className="alert alert-warning" role="alert">
                {" "}
                <FontAwesomeIcon icon="exclamation-circle" /> {formError}
              </div>
            ) : null}

            <div className="col-12 col-sm-6 col-md-6 px-1">
              <div className="form-floating mb-2">
                <input type="text" isInvalid={false} className={"form-control"} name="Description" placeholder="First Name" rows="2" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                <label className="floatingInput">First Name</label>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6  px-1">
              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="Last Name" rows="2" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                <label className="floatingInput">Last Name</label>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 px-1">
              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="Alias" rows="2" onChange={(e) => setAlias(e.target.value)} defaultValue={alias} />
                <label className="floatingInput">Alias</label>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-1">
              <div className="form-floating mb-2">
                <select className="form-control" name="BetBy" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value={"Full-Time"}>Full-Time</option>
                  <option value={"Part-Time"}>Part-Time</option>
                  <option value={"Guest"}>Guest</option>
                </select>
                <label className="floatingInput">Status</label>
              </div>
            </div>

            <div className="col-12 col-sm-8 px-1">
              <div className="form-floating mb-2">
                <input type="text" className="form-control" name="Description" placeholder="What is the bet?" rows="2" onChange={(e) => setImagePath(e.target.value)} defaultValue={imagePath} />
                <label className="floatingInput">Image URL</label>
                <i className="small">* URL required. Uploads not supported yet.</i>
              </div>
            </div>

            <div className="col-12 col-sm-4 px-0 mx-auto text-center">
              <img src={imagePath} className="img-preview img-fluid mt-lg-1 border border-1 rounded-circle " alt={"Bettor preview"} />
              <label className="floatingInput d-block">Preview</label>
            </div>

            {errorList.length > 0 ? (
              <div className="col-12">
                <ul className="alert alert-danger px-4" role={"alert"}>
                  {errorList.map((e) => (
                    <li key={e} className="">
                      {e}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            <div className="form-group mt-2 text-right">
              <button type="button" className="btn btn-danger mx-1" onClick={handleCloseForm}>
                <FontAwesomeIcon icon="times" /> Cacncel
              </button>
              <button type="button" onClick={() => CreateBettor()} className="btn btn-success mx-1">
                <FontAwesomeIcon icon="save" /> Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewBettorForm;
