// Supabase
import { createClient } from "@supabase/supabase-js";

// Dicebear
import { createAvatar } from '@dicebear/core';
import { personas, initials, shapes } from '@dicebear/collection';

// Other 
import hexgen from 'hex-generator';


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export async function UploadImageByUri(imgUri, type, fileName, parentId) {
  const blob = await (await fetch(imgUri)).blob();
  // console.log("Blob:", blob)
  if (blob.size > 2000000) return { success: false, message: "File size must be under 2MB." };
  if (blob.type !== "image/png" && blob.type !== "image/jpg" && blob.type !== "image/jpeg") {
    return { success: false, message: "File must be a PNG or JPEG type." };
  }
  let filePath = type + (parentId ? "/" + parentId : "") + "/" + fileName;
  const { data, error } = await supabase.storage.from("public").upload(filePath, blob, {
    cacheControl: "3600",
    upsert: false,
    contentType: blob.type,
  });

  if (error) {
    return { success: false, message: "" };
  } else {
    return { success: true, message: "File successfully uploaded.", filePath: data.path };
  }
}

export async function GenerateAvatar(type = "personas", seed) {
  const avatar = GetAvatarOptions(type, seed);
  const png = await avatar.png();
  let imgUri = await png.toDataUri();
  return imgUri;
}


function GetAvatarOptions(type = "personas", seed = new Date().getMilliseconds()) {
  switch (type) {
    case "personas":
      return createAvatar(personas, {
        seed: new Date(),
        size: 96,
        backgroundColor: [hexgen(24)]
      });
    case "initials":
      return createAvatar(initials, {
        seed: seed,
        size: 96,
        backgroundColor: [hexgen(24)]
      });
    case "shapes":
      return createAvatar(shapes, {
        seed: seed,
        size: 96,
        backgroundColor: [hexgen(24)]
      });
    default:
      break;
  }
}
