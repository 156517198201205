import { Link } from "react-router-dom";

export default function Terms(props) {

  return (
    <div className="container">
      <div id="page-title">Terms</div>
      <div className="container">
        <div className="row">
          <div className="col-12">

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Description of Service</div>
                </div>
                <div className="section-main">
                  <ol className="text-left">
                    <li>PizzaBets provides a platform via its website where a bets and predictions can be made by registered users ("users" or "you") within a group/community.</li>
                    <li>Bets and predictions can be made and are intended for entertainment purposes only.</li>
                    <li>Prizes/winnings may be added to bets and PizzaBets holds no responsibility for selecting a winner and/or ensuring any prizes/winnings are followed through.</li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">User Conduct</div>
                </div>
                <div className="section-main">
                  <div>As a condition of use, you promise to abide by our Content Guidelines and not to use the Services for any purpose that is unlawful or prohibited by these Terms,
                    or any other purpose not reasonably intended by PizzaBets. By way of example, and not as a limitation, you agree not to use the Services:</div>
                  <ol className="text-left">
                    <li>To abuse, harass, threaten, impersonate, or intimidate any person.</li>
                    <li>To post or transmit, or cause to be posted or transmitted, any Content that is libelous, defamatory, obscene, pornographic, abusive, offensive, profane, or that infringes any copyright or other right of any person</li>
                    <li>To communicate with PizzaBets representatives or other users in an abusive or offensive manner.</li>
                    <li>To post or transmit, or cause to be posted or transmitted, any Communication designed or intended to obtain password, account, or private information from any PizzaBets user</li>
                    <li>To sell, assign, or otherwise transfer your Profile or account</li>
                    <li></li>
                  </ol>
                  <div>
                    <p>
                      To report a suspected abuse of the Site or a breach of the Terms, you maybe complete and submit a report via our <Link to="/Report" className="btn btn-link m-0 p-0">Report Form</Link>.
                    </p>
                    <p>
                      You are solely responsible for your interactions with other users of the Site. PizzaBets reserves the right, but has no obligation, to review disputes between you and other users.
                      This includes the right to review messages exchanged through PizzaBets's messenger service, based on any reports that PizzaBets receives alleging the violation of these Terms
                      through the use of PizzaBets's messenger service, including without limitation, reports regarding alleged harassment, indecency, and offensive messages.
                    </p>
                    <p>
                      If the Services or Site are used in a manner that violates the Terms in any way, PizzaBets may at its sole discretion, but is not required to, suspend or terminate your account,
                      disable your access to the Site and/or take any steps that it deems appropriate to address the situation.
                    </p>

                  </div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Termination of Your Account</div>
                </div>
                <div className="section-main">
                  <ol>
                    <li></li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Warranty Disclaimer</div>
                </div>
                <div className="section-main">
                  YOUR USE OF THE SITE AND THE SERVICES IS AT YOUR OWN RISK. THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS.
                  PizzaBets EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                  PizzaBets DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR IN ANY WAY RELATED TO (A)
                  ANY ERRORS IN OR OMISSIONS FROM THIS SITE AND THE SERVICES, INCLUDING, BUT NOT LIMITED TO, TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B) THIRD PARTY
                  COMMUNICATIONS, (C) ANY THIRD PARTY WEBSITES OR CONTENT DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS IN THE SITE, INCLUDING BUT NOT LIMITED TO ANY ERRORS OR
                  OMISSIONS, (D) THE UNAVAILABILITY OF ALL OR ANY PART OF THE SITE OR THE SERVICES,
                  (E) YOUR USE OF THE SITE OR THE SERVICES, OR (F) YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THE SITE OR THE SERVICES.
                </div>
              </div>
            </div>


            <div className="section">
              <div className="section-content">
                <div className="section-title subheading px-2">
                  <div id="subheading">Privacy Policy</div>
                </div>
                <div className="section-main">
                  <ol>
                    <li>Please refer to our <Link to="/Privacy" className="btn btn-link p-0 m-0">Privacy Policy</Link> for additional information on how PizzaBets stores, uses and processes personal information</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

