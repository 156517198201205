import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

export default function Account(props) {
  const [errorList, setErrorList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => { }, []);

  async function SaveChanges(e) {
    e.preventDefault();

    setIsSaving(true);
    setErrorList([]);
    let tmpErrors = [];

    if (password === "") {
      tmpErrors.push("Password must not be blank");
    }
    if (newPassword === "") {
      tmpErrors.push("New password must at least 6 characters long");
    }

    if (newPassword !== confirmPassword) {
      tmpErrors.push("Passwords do not match");
    }

    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsSaving(false);
      return;
    }

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/account/changepassword",
      data: { password, newPassword, confirmPassword },
    })
      .then((res) => {
        props.handleToggleChangePassword(true);
      })
      .catch((err) => {
        setErrorList(err.response.data);
      });
    setIsSaving(false);
  }

  return (
    <form action="#" className="is-valid" onSubmit={(e) => SaveChanges(e)}>
      <div className="bettor-edit row px-1">
        <div className="subheading-sm">Change Password</div>

        <div className="form-floating mb-2">
          <input type="password" className="form-control" name="Password" placeholder="Current Password" onChange={(e) => setPassword(e.target.value)} defaultValue={password} required />
          <label className="floatingInput ms-2">Current Password</label>
        </div>

        <div className="form-floating mb-2">
          <input type="password" className={"form-control"} name="NewPassword" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} defaultValue={newPassword} required />
          <label className="floatingInput ms-2">New Password</label>
        </div>

        <div className="form-floating mb-2">
          <input type="password" className="form-control" name="ConfirmPassword" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} defaultValue={confirmPassword} required />
          <label className="floatingInput ms-2">Confirm Password</label>
        </div>

        {errorList.length > 0 ? (
          <div className="col-12">
            <ul className="alert alert-danger px-4" role="alert">
              {errorList.map((e) => (
                <li key={e} className="">
                  {e}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="col-12 px-1">
          <div className="form-group mt-2 text-right">
            {isSaving ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
            <button type="button" className="btn btn-danger mx-1" onClick={() => props.handleToggleChangePassword()} disabled={isSaving}>
              <FontAwesomeIcon icon="times" /> Cancel
            </button>

            <button type={"submit"} className="btn btn-success mx-1" onClick={() => SaveChanges} disabled={isSaving}>
              <FontAwesomeIcon icon="save" /> Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
