// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

export default function User(props) {

    useEffect(() => {
    }, []);

    async function CreateEmailVerificationToken() {
        Axios({
            method: "POST",
            withCredentials: true,
            url: process.env.REACT_APP_API_URL + "/account/send-verification-email",
        }).then((res) => {
            console.log("Email verification token sent:", JSON.stringify(res))
        }).catch(err => {
            console.log("Error creating email verification token")
            console.log(err)
        });

    }

    return (
        <div className="row">

            <div className="section">
                <div className="section-content">
                    <div className="section-title subheading">
                        <label>Tools</label>
                    </div>
                    <div className="section-main">
                        <button className="btn btn-primary" onClick={() => CreateEmailVerificationToken()}>Create Email Verifiction Token</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

