import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Bettors from "../components/Bettors";
import Episode from "../components/episodes/episode";
import Shows from "../components/shows/Shows";

function Moderate(props) {
  // alert(JSON.stringify(props.user))
  // if (!props.user)
  //   window.location.href = "/login";
  // else if (props.user && !props.user.moderator)
  //   window.location.href = "/";

  const [errorMessages, setErrorMessages] = useState([]);
  const [visibleSection, setVisibleSection] = useState("Shows");

  // const [user, setUser] = useState("");
  const [betEvents, setBetEvents] = useState([]);

  useEffect(() => {
    LoadTab();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function LoadTab() { }

  return (
    <div className="container">
      <div id="page-title">Moderator Page</div>

      <div className="row" hidden>
        <div className="col-12">
          <input type="text" className="form-control" placeholder="Search..." />
        </div>
      </div>

      <div className="row mb-2 pb-2 border-bottom border-dark">
        <div className="col-12">
          <button type="text" className={"btn btn-" + (visibleSection === "Shows" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Shows")}>
            Shows
          </button>
          <button type="text" className={"btn btn-" + (visibleSection === "Bettors" ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={(e) => setVisibleSection("Bettors")}>
            Bettors
          </button>
          {/* <button type="text" className={"btn btn-" + ((visibleSection === "Episodes") ? "" : "outline-") + "secondary btn-sm mx-1"} onClick={e => setVisibleSection("Episodes")}>Episodes/Bets</button> */}
        </div>
      </div>

      {visibleSection === "Shows" ? <Shows key={"Shows"} user={props.user} /> : null}

      {visibleSection === "Bettors" ? <Bettors key={"Bettors"} user={props.user} /> : null}

      {/* <div className="row mt-2">
        {betEvents.map(e =>
          <BetEvent key={e.id} betEvent={e} user={props.user} />
        )}
      </div> */}
    </div>
  );
}

export default Moderate;
