import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { validateImage } from "image-validator";

// Scripts
import { UploadImageByUri, GenerateAvatar } from "../scripts/upload";

// Components
import CropView from "./CropView";

const EditBettor = (props) => {

  const [firstName, setFirstName] = useState(props.bettor.firstname);
  const [lastName, setLastName] = useState(props.bettor.lastname);
  const [alias, setAlias] = useState(props.bettor.alias);
  const [status, setStatus] = useState(props.bettor.status);
  //const [imagePath, setImagePath] = useState(props.bettor.imagepath);
  // const [imagePathValid, setImagePathValid] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showCropImage, setShowCropImage] = useState(false);
  const [imageObject, setImageObject] = useState(process.env.REACT_APP_BETTOR_IMAGE_PATH + props.bettor.imagepath);
  const [preCropImageObject, setPreCropImageObject] = useState(null);
  useEffect(() => {
  }, []);

  async function SaveChanges(e) {
    e.preventDefault();
    //e.preventDefault();
    setErrorList([]);
    setIsSaving(true);

    //let imageSuccess = checkIfImageExists(imagePath);
    //console.log(imageSuccess)
    let tmpErrors = [];
    if (firstName === "") {
      tmpErrors.push("First name must not be blank");
    }
    if (lastName === "") {
      tmpErrors.push("Last name must not be blank");
    }

    if (alias === "") {
      tmpErrors.push("Alias name must not be blank");
    }

    let fileName = props.bettor.imagepath;
    let dateUTC = new Date().toUTCString();
    let now = dateFormat(dateUTC, "yyyy-mm-dd_hh:MM:ss");
    fileName = props.bettor.id + "_" + now + ".png";

    let isValidImage = await validateImage(imageObject);
    if (!isValidImage) {
      console.log("The media resource is either invalid, corrupt or unsuitable");
      return null;
    } else {
      console.log("Image okay.");
      var upload = await UploadImageByUri(imageObject, "bettors", fileName);
      if (!upload.success) {
        // console.log("Failed to upload", upload.message);
        tmpErrors.push("Image file size exceeds 2MB limit.");
      }
    }

    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsSaving(false);
      return;
    }

    let bettorData = {
      id: props.bettor.id,
      firstname: firstName,
      lastname: lastName,
      alias: alias,
      status: status,
      imagepath: fileName,
    };

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bettors/update",
      data: bettorData,
    }).then(res => {
      console.log("Save result: " + JSON.stringify(res.data));
      if (res.status === 200) {
        props.updateBettor(bettorData);
      } else {
        setErrorList(["There was an issue update the information. Please try again later!"]);
        setIsSaving(false);
      }
    });
  }

  async function InitiateImageCrop(e) {
    let imgURL = URL.createObjectURL(e.target.files[0])
    console.log("CROPS:", e.target.files[0])
    setPreCropImageObject(imgURL);
    setShowCropImage(true);
  }

  async function handleApplyCrop(e) {
    console.log("Appling crop:", e)
    setImageObject(e);
    setShowCropImage(false);
  }

  const handleCancelCrop = () => {
    //setImageObject(props.user.fullimageurl);
    setPreCropImageObject(false)
    setShowCropImage(false);
    setIsSaving(false);
    setImageObject(process.env.REACT_APP_BETTORS_IMAGE_PATH + props.user.imageurl);
  };


  const handleCancelEdit = () => {
    //setImageObject(props.user.fullimageurl);
    setShowCropImage(false);
    setImageObject(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
    //props.handleToggleEdit();
  };


  return (
    <form action="#" className="is-valid" onSubmit={e => SaveChanges(e)}>
      <div className="bettor-edit row">
        <div className="subheading">Edit</div>

        {/* <div className="col-12 staff-img-container mx-auto text-center ">
          <img className="staff-img" src={imageObject} alt="User" />
            <label htmlFor="file-input" className="upload-icon-container btn btn-outline-light">
              <FontAwesomeIcon icon="arrow-up-from-bracket" htmlFor="file-input" />
            </label>
            <input id="file-input" type={"file"} className="d-none" onChange={(e) => InitiateImageCrop(e)} />
            {/* <input id="file-input" type={"file"} className="d-none" onChange={(e) => setImageObject(e.target.files[0])} /> * /}
        </div> */}

        <div className="col-12 col-sm-6 col-md-12 col-lg-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className={"form-control"} name="Description" placeholder="First Name" rows="2" onChange={e => setFirstName(e.target.value)} defaultValue={firstName} />
            <label className="floatingInput">First Name</label>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-12 col-lg-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Last Name" rows="2" onChange={e => setLastName(e.target.value)} defaultValue={lastName} />
            <label className="floatingInput">Last Name</label>
          </div>
        </div>

        <div className="col-12 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Alias" rows="2" onChange={e => setAlias(e.target.value)} defaultValue={alias} />
            <label className="floatingInput">Alias</label>
          </div>
        </div>

        <div className="col-12 col-sm-8 col-md-12 col-lg-8 px-1">
          <div className="form-floating mb-2">
            <select className="form-control" name="BetBy" defaultValue={status} onChange={e => setStatus(e.target.value)}>
              <option disabled>{status} - [Current]</option>
              <option value={"Full-Time"}>Full-Time</option>
              <option value={"Part-Time"}>Part-Time</option>
              <option value={"Guest"}>Guest</option>
            </select>
            <label className="floatingInput">
              <FontAwesomeIcon icon={"chevron-down"} /> Status
            </label>
          </div>
        </div>

        <div className="col-12 col-sm-4 col-md-12 col-lg-4 staff-img-container">
          <img className="staff-img" src={imageObject} alt="User" />
          <label htmlFor="file-input" className="upload-icon-container btn btn-outline-light">
            <FontAwesomeIcon icon="arrow-up-from-bracket" htmlFor="file-input" />
          </label>
          <input id="file-input" type={"file"} className="d-none" onChange={(e) => InitiateImageCrop(e)} />
        </div>
        {/* <small className="col-12 text-sm">{JSON.stringify(imageObject)}</small> */}

        {/* 
        <div className="col-12 col-sm-4 px-0 mx-auto text-center">
          <img src={imageObject} className="img-preview img-fluid mt-lg-1 border border-1 rounded-circle " alt={"Bettor preview"} />
          <label className="floatingInput d-block">Preview</label>
        </div> */}



        {showCropImage === true ? (
          <CropView key={"CropView-" + props.bettor.id} file={preCropImageObject} handleApplyCrop={(e) => handleApplyCrop(e)} handleCancelCrop={handleCancelCrop} />
        ) : null}


        {errorList.length > 0 ? (
          <div className="col-12">
            <ul className="alert alert-danger px-4" role={"alert"}>
              {errorList.map(e => (
                <li key={e} className="">
                  {e}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="col-12 px-1">
          <div className="form-group mt-2 text-right">
            {isSaving ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
            <button type="button" className="btn btn-danger mx-1" onClick={props.handleCancelEdit} disabled={isSaving}>
              <FontAwesomeIcon icon="times" /> Cancel
            </button>

            <button type={"submit"} className="btn btn-success mx-1" onClick={() => SaveChanges} disabled={isSaving}>
              <FontAwesomeIcon icon="save" /> Save
            </button>

            <button type="button" className="btn btn-outline-danger pull-left mx-1" onClick={() => alert("Sorry, this feature is not available.")}>
              <FontAwesomeIcon icon="trash" disabled={isSaving} />
            </button>
          </div>
        </div>
      </div>
    </form >
  );
};

export default EditBettor;
