import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import TextareaAutosize from "react-textarea-autosize";

function EditEpisodeForm(props) {
  const [bettors, setBettors] = useState([]);
  const [eventTypesList, setEventTypesList] = useState([]);
  const [eventType, setEventType] = useState(props.episode.eventtypeid);
  const [title, setTitle] = useState(props.episode.title);
  const [description, setDescription] = useState(props.episode.description);
  const [eventDate, setEventDate] = useState(dateFormat(props.episode.dateofevent, "yyyy-mm-dd"));
  const [errorList, setErrorList] = useState([]);
  const [newImageUrl, setNewImageUrl] = useState(props.episode.eventtypelogo);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    LoadTab();
  }, []);

  async function LoadTab() {
    await getEventTypes();
  }

  function SaveChanges(e) {
    e.preventDefault();
    setIsLoading(true);
    let tmpErrors = [];

    if (title === "") {
      tmpErrors.push("Title must not be blank");
    }
    if (description === "") {
      tmpErrors.push("Description name must not be blank");
    }
    if (eventDate === "") {
      tmpErrors.push("Date name must not be blank");
    }
    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsLoading(false);
      return;
    }

    let updatedEventData = {
      id: props.episode.id,
      newEventTypeId: eventType,
      newTitle: title,
      newDescription: description,
      newEventDate: eventDate,
    };

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/updateEvent",
      data: updatedEventData,
    }).then((res) => {
      console.log("RES: ", res);
      if (res.status === 200) {
        updatedEventData.newImageUrl = newImageUrl;
        console.log(JSON.stringify(res))

        props.handleSaveChanges(updatedEventData);
      } else {
        setErrorList(["There was an issue saving your changes."]);
      }
      setIsLoading(false);
    });
  }

  const getEventTypes = async () => {
    await Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/geteventtypes",
    }).then((res) => {
      setEventTypesList(res.data);
    });
    setIsLoading(false);
  };

  return (
    <form className="section mb-2 editform-active" onSubmit={(e) => SaveChanges(e)}>
      <div className="section-content">
        <div className="section-title subheading px-2">Edit</div>
        <div className="section-main">
          <div className="row">
            <div className="col-12 col-lg-6 mb-2">
              <div className="form-floating">
                <select
                  className="form-control"
                  name="EventType"
                  value={eventType}
                  onChange={(e) => {
                    setEventType(e.target.value);
                    console.log(eventType);
                    setNewImageUrl(e.target[e.target.selectedIndex].getAttribute("data-imageurl"));
                  }}
                  selected={0}
                  required
                >
                  <option value={props.episode.eventtypeid}>{props.episode.eventtypetitle} - [CURRENT]</option>
                  {isLoading ?
                    <option key={"editEpisode-loading-type"} value={0} disabled>Loading...</option>
                    :
                    eventTypesList.map((e) => (
                      <option key={e.id} value={e.id} data-imageurl={e.imageurl}>
                        {e.title} - {e.description}
                      </option>
                    ))
                  }
                </select>
                <label className="floatingInput">
                  <FontAwesomeIcon icon={"chevron-down"} /> Event
                </label>
              </div>
            </div>

            <div className="col-12 col-lg-6 mb-2">
              <div className="form-floating">
                <input type="text" className={"form-control"} name="EventTitle" placeholder="Event Title (e.g. podcast episode title)?" value={title} onChange={(e) => setTitle(e.target.value)} />
                <label className="floatingInput">Title</label>
              </div>
            </div>

            <div className="col-12 col-lg-6 mb-2">
              <div className="form-floating">
                <TextareaAutosize className="form-control" name="EventDescription" placeholder="Event description (e.g. podcast full description" rows="2" value={description} onChange={(e) => setDescription(e.target.value)} />
                <label className="floatingInput">Description</label>
              </div>
            </div>

            <div className="col-12 col-lg-6 mb-2">
              <div className="form-floating">
                <input type="date" className="form-control" name="EventDate" value={eventDate} onChange={(e) => setEventDate(e.target.value)} />
                <label className="floatingInput">Episode Date</label>
              </div>
            </div>
            {errorList.length > 0 ? (
              <div className="col-12 px-2">
                <ul className="alert alert-danger px-4" role={"alert"}>
                  {errorList.map((e) => (
                    <li key={e} className="">
                      {e}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="col-12 mb-2">
              <div className="form-group text-right">
                {isLoading ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
                <button type="button" className="btn btn-danger mx-2" onClick={props.handleCancelEdit} disabled={isLoading}>
                  <FontAwesomeIcon icon="times" /> Cancel
                </button>
                <button type="submit" className="btn btn-success" disabled={isLoading}>
                  <FontAwesomeIcon icon="save" /> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditEpisodeForm;
