
import React, { useState } from 'react';
import { Link } from "react-router-dom"
import ReactDOM from 'react-dom';
// import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Axios from "axios";


// import App from './App';
//import * as serviceWorker from './serviceWorker';

// Styling
import "./custom-bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./themes.scss";
import "./Main.css";

// Pages
import App from "./pages/app"

require('dotenv').config();


ReactDOM.render(< App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

/*
CONFIG VAR FROM HEROKU
DATABASE_URL
postgres://mnpxwptrmxyssp:32fdc6d97ad6b35b2f606efa1f57d122ffc77f6ec06f7eee83551b00a0c3e900@ec2-3-237-55-151.compute-1.amazonaws.com:5432/dcsolq2ca4a3va

*/
