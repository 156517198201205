// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// Components
import Bettor from "./Bettor";
import AddNewBettor from "./newBettor";
import Loading from "./loadingEvent";

export default function Bettors(props) {
  const [showNewBettorForm, setShowNewBettorForm] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [bettors, setBettors] = useState(null);

  useEffect(() => {
    LoadData();
  }, []);

  async function LoadData() {
    await Axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/getBettors",
    }).then((res) => {
      if (res.data[0]) {
        setBettors(res.data);
      } else {
        setErrorMessages(res.data[1].errors);
      }
    });
  }

  function UpdateBettorList(e) {
    LoadData();
    /* TODO: Just add individual new record to list instead of loading in all bettor again
      setBettor(bettors => [...bettors, e])
    */
  }
  const updateBettor = (bettorrData) => {
    setBettors((rows) => rows.map((r) => (r.id === bettorrData.id ? bettorrData : r)));
  };

  return (
    <div className="row">
      <div className="subheading">
        Group Bettors
        <button className="btn btn-outline-success btn-sm pull-right" title="Add new bettors" onClick={() => setShowNewBettorForm(!showNewBettorForm)}>
          <FontAwesomeIcon icon="plus" /> New
        </button>
      </div>

      {showNewBettorForm === true ? <AddNewBettor key={"AddNewBettor"} user={props.user} handleCloseForm={() => setShowNewBettorForm(false)} addNewBettor={(e) => UpdateBettorList(e)} /> : null}

      <div className="subheading-sm">Full Timers</div>
      {bettors ?
        bettors.filter((e) => e.status === "Full-Time")
          .map((e) => (
            <Bettor key={e.id} bettor={e} updateBettorMember={updateBettor} />
          ))
        :
        <Loading />
      }
      <hr className="my-2" />

      <div className="subheading-sm">Part Timers</div>
      {bettors ?
        bettors.filter((e) => e.status === "Part-Time")
          .map((e) => (
            <Bettor key={e.id} bettor={e} updateBettor={updateBettor} />
          ))
        : <Loading />
      }
      <hr className="my-2" />
      <div className="subheading-sm">Guests</div>
      {
        bettors ?
          bettors.filter((e) => e.status === "Guest")
            .map((e) => (
              <Bettor key={e.id} bettor={e} updateettor={updateBettor} />
            ))
          : <Loading />
      }
    </div>
  );
}

