import React, { useState, useEffect, useCallback, } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// Components
import EditProfile from "../components/profile/editProfile";
import ChangePassword from "../components/profile/changePassword";
import Persona from "../components/profile/generatePersona";
import { getInitialCropFromCroppedAreaPixels } from "react-easy-crop";

const stripe = require('stripe')(process.env.REACT_APP_STRIPE_KEY);

export default function Account(props) {
  // if (!props.user) {
  //   window.location.href = "/login";
  // }

  const [editModalClass, setEditModalClass] = useState(" editform-active");
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [showCropImage, setShowCropImage] = useState(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [theme, setTheme] = useState(props.theme);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [changePasswordClass, setChangePasswordClass] = useState(" editform-active");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const { session_id } = useParams();

  useEffect(() => { }, []);

  useEffect(() => {
    setImageFile(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
    GetSubscriptions();
  }, [props.user]);

  useEffect(() => {
    setEditModalClass(editModeEnabled ? " editform-active" : "");
  }, [editModeEnabled]);

  useEffect(() => {
    setChangePasswordClass(showChangePassword ? " editform-active" : "");
  }, [showChangePassword]);

  const handleToggleEdit = () => {
    setEditModeEnabled(!editModeEnabled);
    setImageSelected(null);
    setImageFile(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
  };

  async function handleToggleChangePassword(passwordChanged = false) {
    setShowChangePassword(!showChangePassword);
    setChangePasswordClass("");
    setChangePasswordSuccess(passwordChanged);
  }

  const updateUser = (userData) => {
    props.updateUser(userData);
    setEditModeEnabled(false);
  };

  const CreateSession = async () => {
    console.log("Create session...")

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/Account/Subscribe",
    }).then((res) => {
      if (res) {
        window.location.href = res.data.url;
      } else {
        console.log("There was an issue subscribing.");
      }
    }).catch(err => {
      console.log("There was an issue subscribing: " + err);
    });
  }

  const GetSubscriptions = async () => {
    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/Stripe/subscriptions",
    }).then((res) => {
      if (res) {
        setSubscriptions(res.data.data)
      } else {
        console.log("There was an issue getting subs.");
      }
    }).catch(err => {
      console.log("There was an issue getting subs");
    });
  };

  const ViewCustomerPortal = async () => {
    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/stripe/customer-portal",
    }).then(res => {
      window.location.href = res.data;
    }).catch((err) => {
      console.log("Error getting customer portal.")
    });
  };

  const logout = () => {
    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/logout",
    }).then((res) => {
      if (res) {
        window.location.href = "/";
      } else {
        alert("There was an issue logging out.");
      }
    });
  };

  return (
    <div id="account" className="container">
      <div id="page-title">Account</div>
      <div className="row">

        <div className={"section " + editModalClass + " " + changePasswordClass}>
          <div className="section-content">
            <div className="section-title subheading px-2">Profile</div>
            <div className="section-main">
              <div className="row position-relative">
                {!editModeEnabled ?
                  <>
                    <div className="profile-img-container">
                      <img className="profile-img" src={imageFile} alt="User" />
                    </div>

                    <div className="col pe-5">
                      <div className="bettor-name">{props.user.firstname + " " + props.user.lastname}</div>
                      <div className="bettor-description">{props.user.email}</div>
                      <div className={"px-0"}>

                        <div className="btn btn-link btn-sm" onClick={() => handleToggleChangePassword(false)} hidden={showChangePassword}>
                          Change Password
                        </div>

                        {changePasswordSuccess ? (
                          <div className="alert alert-success col-12 mt-2" role="alert">
                            <FontAwesomeIcon icon="check" /> Password successfully updated!
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                  : null}

                {!showChangePassword && !editModeEnabled ?
                  <button className="col d-inline-block btn btn-outline-secondary btn-sm edit-button" title="Edit Profile" onClick={handleToggleEdit}>
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  : null}
              </div>

              {showChangePassword ? <ChangePassword key={"ChangePassword"} handleToggleChangePassword={handleToggleChangePassword} /> : null}

              {editModeEnabled ? <EditProfile key={"editProfile-" + props.user.id} user={props.user} fileObject={imageFile} showCropImage={showCropImage} handleToggleEdit={handleToggleEdit} updateUser={updateUser} imageSelected={imageSelected} /> : null}
            </div>
          </div>
        </div>

        <div className="section">
          <div className="section-content">
            <div className="section-title subheading">
              <label>Membership</label>
            </div>
            <div className="section-main">
              <div className={"profile-item row px-0 "}>
                {session_id && session_id !== "" ?
                  <div className="alert alert-success" role="alert"><FontAwesomeIcon icon="circle-check" /> Successuly Subscribed. Thank you!</div>
                  : null}
                {subscriptions && subscriptions.length > 0 ?
                  <div className="col-12">
                    {subscriptions?.map(s => (
                      <ul key={s.id}>
                        <li >Status: {s.status}</li>
                        <li>Started: {new Date(s.current_period_start * 1000).toLocaleDateString("en-AU")}</li>
                        <li>{s.cancel_at ? "Ends: " : "Renews: "} {new Date(s.current_period_end * 1000).toLocaleDateString("en-AU")}</li>
                      </ul>
                    ))}
                    <div className="col-12">
                      <button className="btn btn-outline-info btn-sm" onClick={() => ViewCustomerPortal()}>Manage Subscription</button>
                    </div>
                  </div>
                  : (subscriptions && subscriptions.length === 0) ?
                    <>
                      <div className="col-12 mb-2">Upgrade to Pro to run your own group</div>
                      <div className="col-3 bold">Subscribe</div>
                      <div className="col-9">
                        <button className="btn btn-success btn-sm" onClick={() => CreateSession()}>Subscribe</button>
                      </div>
                    </>
                    :
                    <div> Loading ...</div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="section-content">
            <div className="section-title subheading">
              <label>Other</label>
            </div>
            <div className="section-main">
              <div className="profile-item row px-0">
                <span className="col-3 bold">Theme</span>
                <span className="col-9">
                  <input type="checkbox" id="theme-toggle" defaultChecked={props.theme === "dark"} onChange={() => props.setTheme(props.theme === "dark" ? "light" : "dark")} />
                  <label htmlFor="theme-toggle"></label>
                </span>
              </div>
              <div className={"profile-item row px-0 "}>
                <span className="col-3 bold">About</span>
                <span className="col-9">
                  <Link className="btn btn-outline-primary border-0 btn-sm" to="/about">About</Link>
                </span>
              </div>

              <div className={"profile-item row px-0 "}>
                <span className="col-3 bold">Terms</span>
                <span className="col-9">
                  <Link className="btn btn-outline-primary border-0 btn-sm" to="/Terms">Terms</Link>
                </span>
              </div>

              <div className={"profile-item row px-0 "}>
                <span className="col-3 bold">Privacy</span>
                <span className="col-9">
                  <Link className="btn btn-outline-primary border-0 btn-sm" to="/Privacy">Privacy</Link>
                </span>
              </div>

              <hr />
              <div className="profile-item row px-0">
                <span className="col text-right">
                  <button className="btn btn-danger btn-sm" onClick={() => logout()}>
                    Logout
                  </button>
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default App;
