import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat, { masks } from "dateformat";
import { useSpring, animated } from "react-spring";

//import "./App.css";
import Axios from "axios";

// components
import EditBet from "./editBet";

export default function Bet(props) {
  const [editBetOpen, setEditBetOpen] = useState(false);
  const [editModalClass, setEditModalClass] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [reacted, setReacted] = useState((props.bet.reactby === props.user?.id && props.bet.reacttype) ?? false)
  const [reactAnimateClass, setReactAnimateClass] = useState("");
  const [betByImageSource, setBetByImageSource] = useState((!props.bet.betby_image.startsWith("data:image/svg+xml;") ? process.env.REACT_APP_BETTOR_IMAGE_PATH : "") + props.bet.betby_image)
  const [betAgainstImageSource, setBetAgainstImageSource] = useState((!props.bet.betagainst_image?.startsWith("data:image/svg+xml;") ? process.env.REACT_APP_BETTOR_IMAGE_PATH : "") + props.bet.betagainst_image)

  useEffect(() => {
    setEditModalClass(editBetOpen ? " editform-active" : "");
  }, [editBetOpen]);

  const handleCancelEdit = () => {
    setEditBetOpen(false);
  };

  const deleteBet = () => {
    if (window.confirm("Are you sure you want to delete this bet?") === false) return;

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/delete",
      data: {
        id: props.bet.id,
      },
    })
      .then((res) => {
        console.log("Bet deleted: " + props.bet.id);
      })
      .catch((err) => {
        console.log("ERROR deleting bet" + err);
      });
  };

  const ReactToBet = async () => {
    setReactAnimateClass("beat");
    setReacted(!reacted)
    setTimeout(() => setReactAnimateClass(""), 500);

    await Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/bets/react",
      data: {
        type: props.bet.reacttype ? null : "LIKE",
        threadid: props.bet.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          SaveBet(props.bet.id);
        } else {
          console.log("Error updating bet React!");
        }
      })
      .catch((err) => {
        console.log("Error updating bet React!", err);
        setReacted(!reacted)
      });
  };

  const SaveBet = (data) => {
    props.updateBet(data);
    handleCancelEdit();
  };

  return (
    <div className={"row bets bet-" + props.bet.id + editModalClass}>
      <div className="bettors col-12 col-md-6 col-lg-4 bet-description">
        <div className="bettor">
          <img className="bettor-img" src={betByImageSource} alt={props.bet.betbyname} />
          <div className="bettor-name">{props.bet.betbyname}</div>
        </div>
        {props.bet.betagainst ? (
          <>
            <div className="vs">bets</div>
            <div className="bettor">
              <img className="bettor-img" src={betAgainstImageSource} alt={props.bet.betagainstname} />
              <div className="bettor-name">{props.bet.betagainstname} </div>
            </div>
          </>
        ) : (
          <div className="vs">predicts</div>
        )}
      </div>
      <div className="bet-description col mb-2">
        <h6>
          <FontAwesomeIcon icon="quote-left" className="mx-1" />
          {props.bet.description}
        </h6>
        {showMore ? (
          <div className="row text-center p-1 my-2">
            <div className="col-12">
              <hr />
              {/* <button className="btn btn-outline-secondary btn-sm d-block mx-auto my-0 py-0 " onClick={() => setShowMore(!showMore)} title="Show less">
                <FontAwesomeIcon icon="chevron-up" />
              </button> */}
              <span className="bold">Due:</span> {dateFormat(props.bet.duedate, "dddd, dS mmm yyyy")}
              {props.bet.winningprize ?
                <div className="col-12 text-center">
                  <span className="bold">Prize:</span> {props.bet.winningprize}
                </div>
                : null}
            </div>
          </div>
        ) : null}
      </div>
      {props.user ? (
        <>
          <div className="bet-menu col-12">
            <div className="row">
              <div className="col" title="Like Bet">
                <button className={"btn btn-outline-light react"} onClick={() => ReactToBet()}>
                  <FontAwesomeIcon icon="heart" className={"me-1 " + (reacted ? "react-heart" : "") + " " + reactAnimateClass} />
                </button>
              </div>
              <div className="col" title="View comments">
                <button className={"btn btn-outline-light"} onClick={() => alert("Comments in development.")}>
                  <FontAwesomeIcon icon="comment" className={"me-1"} />
                </button>
              </div>

              {props.user.admin || props.user.moderator ?
                <div className="col" title="Options">
                  {/* prettier-ignore */}
                  <div className="dropup">
                    <button className="btn btn-outline-light" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                      <FontAwesomeIcon icon="bars" />
                    </button>
                    <ul className="dropdown-menu py-1 col-12">
                      {props.user.admin || props.user.moderator ? (
                        <>
                          <li className="mx-2">
                            <button className="btn btn-outline-light text-left px-2" onClick={() => setEditBetOpen(!editBetOpen)}>
                              <FontAwesomeIcon icon="edit" className="pull-left mt-1 me-2" /> <span>Edit</span>
                            </button>
                          </li>
                          <li className="mx-2">
                            <button className="btn btn-outline-light text-left px-2" onClick={() => deleteBet()}>
                              <FontAwesomeIcon icon="trash" className="pull-left mt-1 me-2" /> <span>Delete</span>
                            </button>
                          </li>
                        </>
                      ) : null}
                      <li className="mx-2">
                        <button className="btn btn-outline-light text-left px-2" onClick={() => alert("Sorry, this feature is not yet available and is coming soon.")} >
                          <FontAwesomeIcon icon="flag" className="pull-left mt-1 me-2" /> <span>Report</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div >
                : null}
              <div className="col">
                {showMore ?
                  <button className="btn btn-outline-light" onClick={() => setShowMore(!showMore)} title="Show Less">
                    <FontAwesomeIcon icon="chevron-up" />
                  </button>
                  : <button className="btn btn-outline-light" onClick={() => setShowMore(!showMore)} title="Show More">
                    <FontAwesomeIcon icon="chevron-down" />
                  </button>}
              </div>
            </div>
          </div>
        </>
      ) : null}

      {
        editBetOpen ? (
          <EditBet
            key={props.bet.id}
            bet={props.bet}
            handleCancelEdit={handleCancelEdit}
            updateBet={SaveBet}
          />
        ) : null
      }
    </div >
  );
}

