import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import DatePicker from "react-date-picker";
import { validateImage } from "image-validator";


// Scripts
import { UploadImageByUri, GenerateAvatar } from "../../scripts/upload";

// Components
import CropView from "../CropView";



const EditShow = (props) => {
  const [title, setTitle] = useState(props.e.title);
  const [description, setDescription] = useState(props.e.description);
  // const [imageurl, setImageUrl] = useState(props.e.imageurl);
  const [imageUrlValid, setImageUrlValid] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [imageObject, setImageObject] = useState(process.env.REACT_APP_SHOW_IMAGE_PATH + props.e.imageurl);
  const [preCropImageObject, setPreCropImageObject] = useState(null);
  const [showCropImage, setShowCropImage] = useState(false);

  useEffect(() => { }, []);

  /*
  title, description, image, hosts??
  */

  async function SaveChanges(e) {
    e.preventDefault();
    setIsSaving(true);
    let tmpErrors = [];
    if (title === "") {
      tmpErrors.push("Title must not be blank");
    }
    if (description === "") {
      tmpErrors.push("Description name must not be blank");
    }

    // const isValidImage = await validateImage(imageObject);
    // if (!isValidImage) {
    //   tmpErrors.push("The media resource is either invalid, corrupt or unsuitable");
    // }
    let isValidImage = await validateImage(imageObject);
    let fileName = props.e.imageurl;
    if (!isValidImage) {
      console.log("The media resource is either invalid, corrupt or unsuitable");
      return null;
    } else {
      let dateUTC = new Date().toUTCString();
      let now = dateFormat(dateUTC, "yyyy-mm-dd_hh:MM:ss");
      fileName = props.e.id + "_" + now + ".png";
      console.log("Image okay.");
      var upload = await UploadImageByUri(imageObject, "shows", fileName, props.e.id);
      if (!upload.success) {
        // console.log("Failed to upload", upload.message);
        tmpErrors.push("Image file size exceeds 2MB limit.");
      }
    }


    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsSaving(false);
      return;
    }

    props.updateEvent(title, description, fileName);
    setIsSaving(false);
  }

  async function CheckImage(url) {
    const isValidImage = await validateImage(imageObject);
    setImageUrlValid(isValidImage);
    if (isValidImage) {
      setImageObject(imageObject);
    } else {
      setErrorList(["The media resource is either invalid, corrupt or unsuitable"]);
    }
  }

  async function InitiateImageCrop(e) {
    let imgURL = URL.createObjectURL(e.target.files[0])
    console.log("CROPS:", e.target.files[0])
    setPreCropImageObject(imgURL);
    setShowCropImage(true);
  }

  async function handleApplyCrop(e) {
    console.log("Appling crop:", e)
    setImageObject(e);
    setShowCropImage(false);
  }


  const handleCancelEdit = () => {
    //setImageObject(props.user.fullimageurl);
    //setShowCropImage(false);
    //setImageObject(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
    props.handleCancelEdit();
  };

  const handleCancelCrop = () => {
    //setImageObject(props.user.fullimageurl);
    setPreCropImageObject(false)
    setShowCropImage(false);
    setIsSaving(false);
    setImageObject(process.env.REACT_APP_BETTORS_IMAGE_PATH + props.user.imageurl);
  };


  return (
    <form action="#" className="bettor-edit is-valid row" onSubmit={(e) => SaveChanges(e)}>
      <div className="subheading">Edit</div>

      <div className="col-12 col-sm-9 col-md-12 col-lg-9 mx-0 px-0">
        <div className="col-12 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Show Title" rows="2" onChange={(e) => setTitle(e.target.value)} defaultValue={title} />
            <label className="floatingInput">Title</label>
          </div>
        </div>

        <div className="col-12 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Show Description" rows="2" onChange={(e) => setDescription(e.target.value)} defaultValue={description} />
            <label className="floatingInput">Descritpion</label>
          </div>
        </div>
      </div>


      <div className="col-12 col-sm-3 col-md-12 col-lg-3 mx-auto mx-0 px-0">
        <div className="c m-2 staff-img-container">
          <img className="staff-img" src={imageObject} alt="User" />
          {/* <div className="custom-file"> */}
          <label htmlFor="file-input" className="upload-icon-container btn btn-outline-light">
            <FontAwesomeIcon icon="arrow-up-from-bracket" htmlFor="file-input" />
          </label>
          <input id="file-input" type={"file"} className="d-none" onChange={(e) => InitiateImageCrop(e)} />
          {/* </div> */}
        </div>

      </div>





      {errorList.length > 0 ? (
        <div className="col-12 px-1">
          <ul className="alert alert-danger px-4" role={"alert"}>
            {errorList.map((e) => (
              <li key={e} className="">
                {e}
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {/* <div className="col-12 px-1 px-0 text-center">
        <img src={imageObject} className="img-preview img-fluid border border-1 rounded-circle " alt={"Bettor preview"} />
        <label className="floatingInput d-block">Preview</label>
      </div> */}

      {showCropImage === true ? (
        <CropView key={"CropView-" + props.e.id} file={preCropImageObject} handleApplyCrop={(e) => handleApplyCrop(e)} handleCancelCrop={handleCancelCrop} />
      ) : null}

      <div className="col-12 px-1">
        <div className="form-group mt-2 text-right">
          {isSaving ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
          <button type="button" className="btn btn-danger mx-1" onClick={handleCancelEdit} disabled={isSaving}>
            <FontAwesomeIcon icon="times" /> Cancel
          </button>

          <button type="submit" className="btn btn-success mx-1" onClick={() => SaveChanges} disabled={isSaving}>
            <FontAwesomeIcon icon="save" /> Save
          </button>

          <button type="button" className="btn btn-outline-danger pull-left mx-1" onClick={() => alert("Sorry, this feature is not available.")} disabled={isSaving}>
            <FontAwesomeIcon icon="trash" disabled={isSaving} />
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditShow;
