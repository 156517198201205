import React, { useState, useEffect } from "react";
//import "./App.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import DatePicker from "react-date-picker";
import { validateImage } from "image-validator";
// Scripts
import { UploadImageByUri, GenerateAvatar } from "../../scripts/upload";

export default function EditUser(props) {
  // const { handleCancelEdit, updateBettor } = props;

  const [firstName, setFirstName] = useState(props.user.firstname);
  const [lastName, setLastName] = useState(props.user.lastname);
  const [email, setEmail] = useState(props.user.email);
  const [emailConfirmed, setEmailConfirmed] = useState(props.user.emailconfirmed);
  const [alias, setAlias] = useState(props.user.alias);
  const [status, setStatus] = useState(props.user.status);
  const [errorList, setErrorList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [imageObject, setImageObject] = useState(props.user.fullimageurl);

  useEffect(() => { }, []);

  async function SaveChanges(e) {
    e.preventDefault();
    //e.preventDefault();
    setErrorList([]);
    setIsSaving(true);
    let bettorData = {
      id: props.user.id,
      firstname: firstName,
      lastname: lastName,
      alias: alias,
      status: status,
    };
    //let imageSuccess = checkIfImageExists(imagePath);
    //console.log(imageSuccess)
    let tmpErrors = [];
    if (firstName === "") {
      tmpErrors.push("First name must not be blank");
    }
    if (lastName === "") {
      tmpErrors.push("Last name must not be blank");
    }

    if (alias === "") {
      tmpErrors.push("Alias name must not be blank");
    }

    if (tmpErrors.length > 0) {
      setErrorList(tmpErrors);
      setIsSaving(false);
      return;
    }

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/admin/updateuser",
      data: bettorData,
    }).then(res => {
      console.log("Save result: " + JSON.stringify(res.data));
      if (res.status === 200) {
        props.updateBettor(bettorData);
      } else {
        setErrorList(["There was an issue update the information. Please try again later!"]);
        setIsSaving(false);
      }
    });
  }
  async function GetNewAvatar() {
    let newAvatar = await GenerateAvatar("personas");
    setImageObject(newAvatar);
  }


  return (
    <form action="#" className="is-valid" onSubmit={e => SaveChanges(e)}>
      <div className="bettor-edit row">
        <div className="subheading">Edit</div>

        <div className="col-12 col-sm-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className={"form-control"} name="Description" placeholder="First Name" rows="2" onChange={e => setFirstName(e.target.value)} defaultValue={firstName} autoComplete={"false"} />
            <label className="floatingInput">First Name</label>
          </div>
        </div>

        <div className="col-12 col-sm-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Last Name" rows="2" onChange={e => setLastName(e.target.value)} defaultValue={lastName} autoComplete={"false"} />
            <label className="floatingInput">Last Name</label>
          </div>
        </div>

        <div className="col-12 col-sm-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Alias" rows="2" onChange={e => setAlias(e.target.value)} defaultValue={alias} autoComplete={"false"} />
            <label className="floatingInput">Alias</label>
          </div>
        </div>

        <div className="col-12 col-sm-6 px-1">
          <div className="form-floating mb-2">
            <input type="text" className="form-control" name="Description" placeholder="Email" rows="2" onChange={e => setEmail(e.target.value)} defaultValue={email} />
            <label className="floatingInput">Email</label>
          </div>
        </div>

        {errorList.length > 0 ? (
          <div className="col-12">
            <ul className="alert alert-danger px-4" role={"alert"}>
              {errorList.map(e => (
                <li key={e} className="">
                  {e}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="col-12 col-sm-6 px-0 text-center">
          <img src={imageObject} className="img-preview img-fluid mt-lg-1 border border-1 rounded-circle " alt={"Profile preview"} />
          <label className="floatingInput d-block">Preview</label>

          <div className="form-floating mb-2">
            <button type="button" className="btn btn-outline-warning btn-sm" onClick={() => GetNewAvatar()} >Generate New Image</button>
          </div>
        </div>

        <div className="col-12 col-sm-6 mt-sm-5 pt-sm-5 px-1">
          <div className="form-group mt-2 text-right">
            {isSaving ? <FontAwesomeIcon icon="spinner" className="fa-spin me-2" /> : null}
            <button type="button" className="btn btn-danger mx-1" onClick={props.handleCancelEdit} disabled={isSaving}>
              <FontAwesomeIcon icon="times" /> Cancel
            </button>

            <button type={"submit"} className="btn btn-success mx-1" onClick={() => SaveChanges} disabled={isSaving}>
              <FontAwesomeIcon icon="save" /> Save
            </button>

            <button type="button" className="btn btn-outline-danger pull-left mx-1" onClick={() => alert("Sorry, this feature is not available.")}>
              <FontAwesomeIcon icon="trash" disabled={isSaving} />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
