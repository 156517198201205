// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// Components
import EditShow from "./editShow";

function EventTypeInfo(props) {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [editModalClass, setEditModalClass] = useState(null);
  const [errorMessages, setErrorMessages] = useState(null);

  useEffect(() => { }, []);

  const handleCancelEdit = () => {
    setEditModeEnabled(false);
  };

  useEffect(() => {
    setEditModalClass(editModeEnabled ? " editform-active" : "");
  }, [editModeEnabled]);

  const updateEvent = (title, description, imageUrl) => {
    let eventInfoData = {
      id: props.e.id,
      title,
      description,
      imageurl: imageUrl,
    };

    Axios({
      method: "POST",
      withCredentials: true,
      url: process.env.REACT_APP_API_URL + "/updateevent",
      data: eventInfoData,
    }).then((res) => {
      if (res.status === 200) {
        props.updateEvent(eventInfoData);
        setEditModeEnabled(false);
      }
    });
  };

  return (
    <div className="col-12 col-md-6 px-0 px-sm-1">
      <div className={"section w-100 " + editModalClass}>
        <div className="section-content">
          <div className="section-main">
            <div className="row position-relative">
              <img className="event-img" src={process.env.REACT_APP_SHOW_IMAGE_PATH + props.e.imageurl} alt="KF Event Logo" />
              <div className="col pe-5">
                <div className="bettor-name"> {props.e.title}</div>
                <div className="bettor-description"> {props.e.description}</div>
                {/* <small>Hosts: ...</small> */}
              </div>
              <button className="btn btn-outline-secondary edit-button btn-sm" title="Edit Event" onClick={() => setEditModeEnabled(!editModeEnabled)}>
                <FontAwesomeIcon icon="edit" />
              </button>
            </div>
            {editModeEnabled ? <EditShow key={"EditEvent-" + props.e.id} e={props.e} user={props.user} handleCancelEdit={handleCancelEdit} updateEvent={updateEvent} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventTypeInfo;
