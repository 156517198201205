// Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

// // reuires
// require("dotenv").config();

import EditUser from "./editUser";
export default function User(props) {
  const [errorMessages, setErrorMessages] = useState(null);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [editModalClass, setEditModalClass] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    setImageFile(process.env.REACT_APP_PROFILE_IMAGE_PATH + props.user.imageurl);
  }, [props.user]);
  useEffect(() => {
    setEditModalClass(editModeEnabled ? " editform-active" : "");
  }, [editModeEnabled]);

  const handleCancelEdit = () => {
    setEditModeEnabled(false);
  };

  const updateBettor = (bettorData) => {
    props.updateBettor(bettorData);
    setEditModeEnabled(false);
  };

  return (
    <div className="col-12 col-lg-6 px-0 px-md-1">
      <div className={"section " + editModalClass}>
        <div className="section-content">
          <div className="section-main">
            <div className="row position-relative ">
              <img className="bettor-img" src={imageFile} alt={props.user.firstname} />
              <div className="col pe-5">
                <div className="bettor-name">{props.user.firstname + " " + props.user.lastname}</div>
                <div className="bettor-email">({props.user.emailconfirmed.toString()})  {props.user.email}</div>
                <div className="">{props.user.alias}</div>
                <div className="">
                  <span className="mx-1 bold" title="Admin Status"><FontAwesomeIcon icon="a" className={(props.user.admin ? "" : "green")} /></span>
                  <span className="mx-1 bold" title="Moderator Status"><FontAwesomeIcon icon="m" className={(props.user.moderator ? "" : "green")} /></span>
                </div>
              </div>
              <button className="col d-inline-block btn btn-outline-secondary btn-sm edit-button" title="Edit Bettor info" onClick={() => setEditModeEnabled(!editModeEnabled)}>
                <FontAwesomeIcon icon="edit" />
              </button>
            </div>
            {editModeEnabled ? <EditUser key={"edituser-" + props.user.id} user={props.user} handleCancelEdit={handleCancelEdit} updateBettor={updateBettor} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

